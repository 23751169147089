import React, { useEffect, useState, useCallback } from "react";
import Context from "../Context";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Input, Button } from "../components/base";
import { Loading } from "../components/modules";

export const Login = () => {
  const recaptchaRef = React.createRef();
  const { callApi } = Context();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");

  const onSubmitWithReCAPTCHA = () => {
    const token = recaptchaRef.current.getValue();
    setToken(token);
  };

  const handleLogin = async () => {
    if (!token) return;
    setIsLoading(true);
    const result = await callApi("users-login", {
      username,
      password,
      reCaptchaToken: token,
      admin: true,
    });

    if (result.success) {
      localStorage.setItem("accessToken", result.data.accessToken);
    } else {
      localStorage.removeItem("accessToken");
    }

    window.location.reload();
  };
  return (
    <div className="w-dvw h-dvh flex justify-center items-center">
      <Container className={`flex flex-col gap-3`}>
        <div>
          <p className="font-medium">บัญชี</p>
          <Input onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div>
          <p className="font-medium">รหัสผ่าน</p>
          <Input
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <form className="" onSubmit={onSubmitWithReCAPTCHA}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LepvVYqAAAAAOZudU8U6HwAe1h2AFpxtQCx7R0i"
            onChange={() => onSubmitWithReCAPTCHA()}
          />
        </form>

        <Button
          disabled={!token}
          onClick={async () => {
            handleLogin();
          }}
        >
          {isLoading ? <Loading className={`h-3 w-3`} /> : "เข้าสู่ระบบ"}
        </Button>
      </Container>
    </div>
  );
};
