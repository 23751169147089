import { useState, useEffect, useRef } from "react";
import Context from "../Context";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components";
import { CSVLink } from "react-csv";
import { thaiDate } from "../util";
import { Loading } from "../components/modules";
import { Container, Text, Switch } from "../components/base";
import {
  ScrollArea,
  Separator,
  useToast,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui";
import { FaTimes, FaCheckCircle } from "react-icons/fa";

export const SendMessages = () => {
  const { callApi, configs } = Context();
  const { toast } = useToast();
  const downloadRef = useRef(null);
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)
  const [isLoading, setLoading] = useState(false);

  const [message, setMessage] = useState(
    "หงษ์ทอง.COM ชวนเพื่อนรวยเพียงแชร์ให้เพื่อนใหม่ซื้อสลากผ่านลิ้งค์รับเลยใบละ 5 บาท เพิ่มเติม :  https://lin.ee/qmOmnRx"
  );
  const [userArray, setUserArray] = useState([]);
  const [userSelected, setUserSelected] = useState([]);

  useEffect(() => {
    getUser();
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const getUser = async () => {
    const users = await callApi("users-getAll", {
      queries: [
        ["event", "!=", "001"],
        ["lineId", "==", ""],
      ],
      limit: 2000,
    });

    setUserArray(users || []);
    console.log("users", users);
  };
  const sendMessage = async () => {
    setLoading(true);
    const { status } = await callApi("utils-sendMessages", {
      user: userSelected,
      message,
    });
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      handleDownload();
      setLoading(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
      });
      setLoading(false);
    }

    setLoading(false);
  };

  const handleDownload = async () => {
    const acc = [];
    acc.push(["ลำดับที่", "userId", "เบอร์", "ข้อความ"]);

    await userSelected.map((user, index) => {
      acc.push([index + 1, user.id, user.phone, message]);
    });

    setCsvData(acc);
    setCsvFileName(`รายงานส่งข้อความ_${thaiDate()}.csv`);
  };
  return (
    <Container className="w-full mb-24  p-8 rounded-lg drop-shadow-xl">
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>
      <Text className={`mt-8 text-xl font-semibold`}>ข้อความ</Text>
      <textarea
        className="p-3 bg-gray-100 w-full h-96 rounded-md"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button
        className="bg-gray-900 rounded-lg px-3 pt-2.5 pb-2 text-white font-semibold min-w-24"
        onClick={() => sendMessage()}
      >
        {isLoading ? <Loading className={`h-4 w-4`} /> : "ส่งข้อความ"}
      </button>
      <button
        className="bg-[#c15b78] rounded-lg ml-3 px-3 pt-2.5 pb-2 text-white font-semibold min-w-24"
        onClick={() => handleDownload()}
      >
        {"ดาวน์โหลด CSV"}
      </button>

      <div>
        <Table className={`mt-11 `}>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[160px] text-center">
                <p
                  className="hover:cursor-pointer"
                  onClick={() =>
                    setUserSelected(
                      userArray.map((user) => {
                        return { id: user.id, phone: user.phone };
                      })
                    )
                  }
                >
                  เลือกทั้งหมด{" "}
                </p>
                <span className="font-semibold">({userSelected.length})</span>
              </TableHead>
              <TableHead className="w-[60px] text-center">ลำดับ</TableHead>
              <TableHead className={`text-center w-[120px]`}>ไอดี</TableHead>
              <TableHead className={`text-center w-fit`}>
                ชื่อ-นามสกุล
              </TableHead>
              <TableHead className={`text-center w-[100px]`}>
                เบอร์โทรศัพท์
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {userArray.map((user, index) => {
              return (
                <TableRow key={index} className={`align-top`}>
                  <TableCell className="font-medium text-center">
                    <input
                      type="checkbox"
                      checked={userSelected.some((x) => x.id === user.id)}
                      onClick={() =>
                        setUserSelected((x) => [
                          ...x,
                          { id: user.id, phone: user.phone },
                        ])
                      }
                    />
                  </TableCell>
                  <TableCell className="font-medium text-center">
                    {index + 1}
                  </TableCell>
                  <TableCell className={`font-bold text-center`}>
                    {user.id}
                  </TableCell>

                  <TableCell className="text-center font-bold text-green-500">
                    {user.firstName + " " + user.lastName}
                  </TableCell>
                  <TableCell className="text-center font-bold text-green-500">
                    {user.phone}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Container>
  );
};
