import { useState, useEffect, useContext } from "react";
import { collection, doc, onSnapshot } from "firebase/firestore";
import {
  Container,
  Input,
  Text,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "../../components/base";
import {
  ScrollArea,
  Avatar,
  AvatarImage,
  AvatarFallback,
  Separator,
  Toaster,
  useToast,
  ToastAction,
  Skeleton,
} from "../../components/ui";
import {
  Loading,
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/modules";
import { CgProfile } from "react-icons/cg";
import { BsPiggyBank } from "react-icons/bs";
import { RiShoppingBag3Fill, RiStickyNoteAddFill } from "react-icons/ri";
import { FiUserPlus, FiEdit3 } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { unixToDateAndTime } from "../../util";
import Context from "../../Context";
import { MdPersonSearch } from "react-icons/md";

import {
  FaCompress,
  FaExpand,
  FaTimes,
  FaCheckCircle,
  FaHourglassHalf,
  FaTimesCircle,
  FaMoneyBillAlt,
  FaShoppingCart,
} from "react-icons/fa";
import { FaCommentMedical } from "react-icons/fa6";
import { MdAccountBalance } from "react-icons/md";
import { banks } from "../../constants";
import { FiShare2 } from "react-icons/fi";

import { useNavigate, useLocation } from "react-router-dom";

export const CreateReward = ({}) => {
  const { callApi, isDarkTheme, themes, configs, db } = Context();
  const { toast } = useToast();

  return <Container>xxx</Container>;
};
