import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";

import {
  Sheet,
  SheetContent,
  SheetTrigger,
  Switch,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import constant from "../../constants";
import { Loading } from "../../components/modules";
import constansts from "../../constants";

export const ReportSale = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [selectedRoundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentChannel, setPaymentChannel] = useState("bank");
  const [isGuest, setIsGuest] = useState(false);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const handleSearch = async () => {
    setLoading(true);
    let keepGoing = true;
    let queries = [["status", "==", constansts.statuses.APPROVE]];

    if (paymentChannel) {
      queries = [...queries, ["paymentChannel", "==", paymentChannel]];
    }

    if (isGuest !== "all") {
      queries = [...queries, ["isGuest", "==", isGuest]];
    }

    const arr = [];
    let startAfterId = "";

    while (keepGoing) {
      const result = await callApi("orders-getAll", {
        roundDate: selectedRoundDate,
        queries: queries,

        limit: 5000,
        startAfter: startAfterId,
        orderBy: ["createdAt", "desc"],
      });

      arr.push(...result);
      startAfterId = result.slice(-1)[0]?.id;
      console.log("result.length", result);
      if (result.length < 4999) {
        keepGoing = false;
      }
    }

    const sumPrice = arr.reduce((acc, couponOrder) => {
      acc += couponOrder.totalPrice;
      return acc;
    }, 0);
    setTotalPrice(sumPrice);
    setData(arr);
    setLoading(false);
  };

  const handleDownload = async () => {
    const acc = [];
    acc.push([
      "ลำดับที่",
      "วันที่ซื้อ",
      "รหัสออเดอร์",
      "ราคา",
      "ชื่อ-นามสกุล",
      "เลขอ้างอิง",
    ]);

    await data.map((order, index) => {
      acc.push([
        index + 1,
        order.createdAtDate,
        order.shortId,
        order.totalPrice?.toLocaleString(),
        order?.firstName + " " + order?.lastName,
        order.slipId || "-",
      ]);
    });

    setCsvData(acc);
    setCsvFileName(`รายงานยอดขาย​  ${selectedRoundDate}.csv`);
  };

  return (
    <div className="bg-white w-full mb-24  p-8 rounded-2xl drop-shadow-xl">
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>

      <div className=" ">
        <div className="mb-3 flex gap-2">
          <div>
            <p className="text-md font-bold">งวด</p>
            <Select
              value={selectedRoundDate}
              onValueChange={(e) => setRoundDate(e)}
            >
              <SelectTrigger className="my-1 w-48 text-md font-medium">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-black">
                {configs.roundDates.map((roundDate) => {
                  return (
                    <SelectItem
                      className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                      value={roundDate}
                      key={roundDate}
                    >
                      {roundDate}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
          <div>
            <p className="text-md font-bold">รูปแบบการชำระ</p>
            <Select
              value={paymentChannel}
              onValueChange={(e) => setPaymentChannel(e)}
            >
              <SelectTrigger className="my-1 w-48 text-md font-medium">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-black">
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value={false}
                >
                  ทั้งหมด
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value="bank"
                >
                  โอนเงิน
                </SelectItem>

                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value="qr"
                >
                  สแกนจ่าย QR
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div>
            <p className="text-md font-bold">ตัวเลือกเฉพาะ</p>
            <Select value={isGuest} onValueChange={(e) => setIsGuest(e)}>
              <SelectTrigger className="my-1 w-48 text-md font-medium">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-black">
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value="all"
                >
                  ทั้งหมด
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value={false}
                >
                  สมาชิกเท่านั้น
                </SelectItem>

                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value={true}
                >
                  ไม่ใช่สมาชิก
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="flex gap-2">
          <button
            className="h-12 w-32 bg-gray-950 hover:shadow-xl duration-200  disabled:hover:bg-gray-950  text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
            onClick={handleSearch}
          >
            {isLoading ? <Loading className={`h-4 w-4`} /> : "ค้นหา"}
          </button>

          <button
            className={`h-12 w-48 bg-[#37beb0]  text-white hover:shadow-xl duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50 ${
              (data.length === 0 || !data) && "hidden"
            }`}
            onClick={() => handleDownload()}
          >
            ดาวน์โหลด
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex w-full justify-center my-48">
          <Loading />
        </div>
      ) : (
        data &&
        (data.length > 0 ? (
          <>
            <div className="my-12">
              <div className="flex gap-2">
                <div
                  className="py-2 max-w-60 px-3 w-full col-span-2 xl:col-span-1 bg-gray-950 text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300"
                  // onClick={() => handleClickSummary("", "")}
                >
                  <p className="text-sm font-medium"> จำนวนออเดอร์ทั้งหมด</p>
                  <div className="text-2xl text-center mt-3 mb-4 font-bold">
                    {data.length}
                  </div>
                  <p className="text-sm text-right font-medium">ออเดอร์</p>
                </div>
                <div
                  className="py-2 max-w-60 px-3 w-full bg-[rgb(59,176,101)] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%]  duration-300"
                  // onClick={() => handleClickSummary("status", "approved")}
                >
                  <p className="text-sm font-medium">จำนวนยอดขายทั้งหมด</p>
                  <div className="text-2xl text-center mt-3 mb-4 font-bold">
                    {totalPrice.toLocaleString()}
                  </div>
                  <p className="text-sm text-right font-medium">บาท</p>
                </div>
              </div>
            </div>

            <Table className="w-full">
              <TableHeader>
                <TableRow>
                  <TableHead className="text-center">ลำดับที่</TableHead>
                  <TableHead className="text-center">วันที่ซื้อ</TableHead>
                  <TableHead className="text-center">รหัสออเดอร์</TableHead>
                  <TableHead className="px-4 ">ชื่อ - นามสกุล</TableHead>
                  <TableHead className="px-4 text-right">ราคา</TableHead>
                  <TableHead className="">เลขอ้างอิง</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((order, index) => {
                  return (
                    <TableRow key={order.shortId}>
                      <TableCell className="text-center font-medium">
                        {index + 1}
                      </TableCell>
                      <TableCell className="font-medium text-center">
                        {new Date(parseInt(order.createdAt)).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <div
                          className={`
                       flex min-w-20 items-center mx-auto bg-gray-950 justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold cursor-pointer`}
                          onClick={() =>
                            window.open(`/orders/${order.id}`, "__blank")
                          }
                        >
                          {order.shortId}
                        </div>
                      </TableCell>
                      <TableCell className="px-4">
                        {order?.firstName + " " + order?.lastName}
                      </TableCell>
                      <TableCell className="px-4 text-right font-semibold">
                        {order.totalPrice?.toLocaleString()}
                      </TableCell>
                      <TableCell className=" overflow-hidden">
                        <p
                          className={`
                     ${
                       order.paymentChannel === "qr"
                         ? "bg-[#37beb0]"
                         : order.slipId
                         ? "bg-gray-950"
                         : "bg-red-500"
                     } w-fit max-w-80 py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold truncate`}
                        >
                          {order.paymentChannel === "qr"
                            ? "QR Code"
                            : order.slipId
                            ? `${order.slipId}`
                            : "ไม่พบ slip"}
                        </p>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        ) : (
          <p className="text-gray-600 text-center text-xl font-semibold py-48">
            ไม่พบคำสั่งซื้อ
          </p>
        ))
      )}
    </div>
  );
};

const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 250px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const Button = styled.button``;
