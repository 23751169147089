import { useState, useEffect } from "react";
import { Container } from "../../components";
import Context from "../../Context";
import { useToast, Separator, ScrollArea } from "../../components/ui";
import { Input } from "../../components/base";
import { Loading } from "../../components/modules";
import { Collapse } from "reactstrap";
import { Dialog } from "../orders/order";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { RiStickyNoteAddFill, RiUserSearchLine } from "react-icons/ri";
import { FaTimes, FaCheckCircle } from "react-icons/fa";

export const WithdrawTransaction = () => {
  const { profileState, callApi, updateProfileState } = Context();
  const { toast } = useToast();

  const [transactions, setTransactions] = useState(false);
  const [isOpenConfirmSheet, setIsOpenConfirmSheet] = useState(false);

  useEffect(() => {
    getTransaction();
  }, []);

  const getTransaction = async () => {
    const result = await callApi("affiliates-getAllWithdrawTransaction");

    setTransactions(
      result.data.map((x) => {
        return { ...x, notes: x.notes?.reverse() };
      })
    );
  };

  const editStatus = async (orderStatus, id) => {
    const { status } = await callApi("affiliates-editStatus", {
      orderId: id,
      status: orderStatus,
    });
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      getTransaction();
      setIsOpenConfirmSheet(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
      });
    }
  };
  return (
    <Container className="w-full mb-24  p-8 rounded-lg drop-shadow-xl">
      <Dialog
        isOpenConfirmSheet={isOpenConfirmSheet}
        setIsOpenConfirmSheet={setIsOpenConfirmSheet}
      />
      <div className="grid mb-2 grid-cols-7 w-full rounded-lg bg-[rgb(228,228,228,0.5)] ">
        <div className="px-3 py-2 font-semibold text-sm mx-auto">วัน-เวลา</div>
        <div className="px-3 py-2 font-semibold text-sm  mx-auto">ไอดี</div>
        <div className="px-3 py-2 font-semibold text-sm  mx-auto">
          ชื่อ-นามสกุล
        </div>
        <div className="px-3 py-2 font-semibold text-sm  mx-auto">เบอร์โทร</div>
        <div className="px-3 py-2 font-semibold text-sm  mx-auto">ถอนจำนวน</div>
        <div className="px-3 py-2 font-semibold text-sm  mx-auto">สถานะ</div>
        <div className="px-3 py-2 font-semibold text-sm  mx-auto">
          แสดง/ซ่อน
        </div>
      </div>
      {!transactions ? (
        <div className="text-2xl text-center h-96 flex items-center justify-center font-bold">
          <Loading />
        </div>
      ) : transactions.length > 0 ? (
        transactions.map((transaction) => (
          <TransactionDetail
            transaction={transaction}
            key={transaction.shortId}
            setIsOpenConfirmSheet={setIsOpenConfirmSheet}
            editStatus={editStatus}
            getTransaction={getTransaction}
          />
        ))
      ) : (
        <div className="text-sm text-center h-96 flex items-center justify-center font-bold text-gray-400">
          <p>ไม่พบ Transaction</p>
        </div>
      )}
    </Container>
  );
};

const TransactionDetail = ({
  transaction,
  setIsOpenConfirmSheet,
  editStatus,
  getTransaction,
}) => {
  const { toast } = useToast();
  const { callApi, isDarkTheme, themes, configs, profileState } = Context();

  const [isOpen, setOpen] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const [isLoading, setLoading] = useState({
    note: false,
    user: false,
  });
  const [user, setUser] = useState(false);

  const submitNote = async (id) => {
    setLoading((prevState) => ({ ...prevState, note: true }));
    const { status } = await callApi("affiliates-addNote", {
      orderId: id,
      note: noteValue,
    });
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      setNoteValue("");
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
      });
    }
    setLoading((prevState) => ({ ...prevState, note: false }));
    getTransaction();
  };

  const getUser = async () => {
    setLoading((prevState) => ({ ...prevState, user: true }));
    const result = await callApi("users-getUserInfoAdmin", {
      userId: transaction.userId,
    });

    setUser(result);
    setLoading((prevState) => ({ ...prevState, user: false }));
  };
  return (
    <div className="mx-2 pt-3  hover:bg-gray-100 duration-150">
      <div className="grid grid-cols-7 mb-3 w-full rounded-lg ">
        <div className="flex  items-center font-semibold mx-auto">
          {new Date(transaction?.createdAt).toLocaleDateString("th-TH", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}{" "}
          น.
        </div>
        <div className=" flex  items-center font-semibold  mx-auto">
          {transaction.shortId}
        </div>
        <div className=" flex  items-center font-semibold  mx-auto">
          {transaction.firstName + " " + transaction.lastName}
        </div>
        <div className=" flex  items-center font-semibold  mx-auto">
          {transaction.phone}
        </div>
        <div className=" flex  items-center font-semibold  mx-auto">
          {(transaction.amount || 0).toLocaleString()}
        </div>
        <div
          className={` flex  items-center font-semibold  mx-auto text-${
            transaction.status === "pending"
              ? "[#ffb067]"
              : transaction.status === "approved"
              ? "[rgb(59,176,101)]"
              : "[#c15b78]"
          }`}
        >
          {transaction.status === "pending"
            ? "รออนุมัติ"
            : transaction.status === "approved"
            ? "อนุมัติ"
            : "ยกเลิก"}
        </div>
        <div className="px-2 flex justify-center items-center mx-auto">
          <div
            onClick={() => setOpen(!isOpen)}
            className="bg-gray-950 p-2 cursor-pointer flex justify-center items-center rounded-lg"
          >
            <p className="text-xl text-gray-50 font-bold">
              {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </p>
          </div>
        </div>
      </div>
      <Collapse className="bg-gray-100 py-3 px-8" isOpen={isOpen}>
        <div className="grid grid-cols-7 gap-2 mb-3 w-full rounded-lg ">
          <div className=" py-3 ">
            <p className="text-sm">ธนาคาร</p>
            <p className="font-semibold "> {transaction.bank.name}</p>
          </div>
          <div className=" py-3 ">
            <p className="text-sm">ชื่อบัญชี</p>
            <p className="font-semibold "> {transaction.bank.accName}</p>
          </div>
          <div className=" py-3 ">
            <p className="text-sm">เลขที่บัญชี</p>
            <p className="font-semibold "> {transaction.bank.accNo}</p>
          </div>
          <div className=" py-3 ">
            <p className="text-sm">จำนวนเงินก่อนหัก</p>
            <p className="font-semibold ">
              {" "}
              {(transaction.balanceBefore || 0).toLocaleString()}
            </p>
          </div>
          <div className=" py-3 ">
            <p className="text-sm">จำนวนเงินคงเหลือ</p>
            <p className="font-semibold ">
              {" "}
              {(transaction.balanceAfter || 0).toLocaleString()}
            </p>
          </div>

          {transaction.status === "pending" && (
            <div className="flex gap-2 justify-center items-center pl-8 py-3 mx-auto">
              <button
                className="px-3 py-1 text-sm font-semibold rounded-lg text-gray-50 bg-[rgb(59,176,101)]"
                onClick={() => {
                  setIsOpenConfirmSheet({
                    body: {
                      text: `อนุมัติคำขอถอนเงิน ?`,
                      func: () => editStatus("approved", transaction.id),
                    },
                  });
                }}
              >
                อนุมัติ
              </button>
              <button
                className="px-3 py-1 text-sm font-semibold rounded-lg text-gray-50 bg-[#c15b78]"
                onClick={() => {
                  setIsOpenConfirmSheet({
                    body: {
                      text: `ยกเลิกคำขอถอนเงิน ?`,
                      func: () => editStatus("canceled", transaction.id),
                    },
                  });
                }}
              >
                ยกเลิก
              </button>
            </div>
          )}
          <div className="pt-3 col-span-3">
            <p className="text-sm mb-2">บันทึกเพิ่มเติม</p>
            <div className="flex bg-white rounded-t-lg ">
              <Input
                className="ml-1 focus:outline-[rgb(59,176,101)]"
                placeholder="บันทึก"
                value={noteValue}
                onChange={(e) => setNoteValue(e.target.value)}
              />
              {isLoading.note ? (
                <div className="flex justify-center items-center px-4 m-1 bg-[rgb(59,176,101)] text-xl text-gray-50 rounded-lg duration-200">
                  <Loading className="w-3 h-3" />
                </div>
              ) : (
                <button
                  className="px-3 m-1 bg-[rgb(59,176,101)] text-gray-50 rounded-lg hover:opacity-85 duration-200"
                  onClick={() =>
                    noteValue === ""
                      ? toast({
                          className: "bg-[#c15b78]",
                          title: (
                            <div className="flex gap-2 items-center">
                              <FaCheckCircle /> โปรดใส่บันทึกเพิ่มเติม
                            </div>
                          ),
                        })
                      : submitNote(transaction.id)
                  }
                >
                  <RiStickyNoteAddFill className="w-6 h-6" />
                </button>
              )}
            </div>
            <ScrollArea className="h-72 w-full rounded-b-lg bg-white">
              {transaction.notes?.length > 0 ? (
                transaction.notes?.map((note) => (
                  <div
                    key={note.time}
                    className="relative flex justify-end p-3 "
                  >
                    <div className="flex flex-col">
                      <p className="text-xs font-semibold">{note.by} </p>
                      <p className="text-lg min-w-48 my-1 px-3 py-2 rounded-lg text-gray-50 font-medium bg-[#168AFF]">
                        {note.note}
                      </p>
                      <p className="text-xs self-end">
                        {new Date(note.time).toLocaleDateString("th-TH", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}{" "}
                        น.
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-sm text-center h-72 flex items-center justify-center font-bold text-gray-400">
                  <p>ไม่พบบันทึก</p>
                </div>
              )}
            </ScrollArea>
          </div>
          <div className="py-3 col-span-4 ">
            <p className="text-sm mb-2 ">ข้อมูลผู้ขอ</p>

            <div className="text-sm  h-72 flex flex-col items-center justify-center font-bold ">
              <div
                className="w-48 flex flex-col justify-center items-center hover:scale-105 cursor-pointer duration-150 text-gray-400 hover:text-gray-500 "
                onClick={async () => {
                  window.open(
                    // constant.webConfigs.customerUrl +
                    "https://admin.hongthongofficial.com/users?" +
                      transaction.userId
                  );
                }}
              >
                <RiUserSearchLine className="w-36 h-36" />
                <p>ข้อมูลผู้ใช้ {transaction.userId}</p>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <arator className="" />
    </div>
  );
};
