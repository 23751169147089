import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import { CSVLink } from "react-csv";
import { Loading } from "../../components/modules";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { HiOutlineDownload } from "react-icons/hi";
import { Separator } from "@radix-ui/react-select";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

export const AffiliateOrder = () => {
  const { callApi, configs } = Context();
  const downloadRef = useRef(null);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)
  const [isLoading, setLoading] = useState(false);
  const [selectedRoundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState(false);
  const [sortedData, setSortedData] = useState(false);
  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
    handleSearch();
  }, [csvFileName, selectedRoundDate]);

  const handleSearch = async () => {
    setLoading(true);

    let res = await callApi("affiliates-getAffiliateOrder", {
      roundDate: selectedRoundDate,
    });

    const sortedData = res.data.reduce((acc, order) => {
      const { referrer } = order;
      acc[referrer] ? acc[referrer].push(order) : (acc[referrer] = [order]);

      return acc;
    }, {});

    setLoading(false);
    setData(res.data);

    console.log("sortedData", sortedData);
    setSortedData(sortedData);
  };

  const handleDownload = async () => {
    const acc = [];
    acc.push([
      "ลำดับที่",
      "Affiliate Id",
      "ออเดอร์",
      "จำนวน (ใบ)",
      "รหัสผู้ซื้อ",
      "รายได้ที่ได้รับ",
    ]);

    await data.map((order, index) => {
      acc.push([
        index + 1,
        order.referrer,
        order.shortId,
        order.size,
        order.userId,
        order.size * 12,
      ]);
    });

    setCsvData(acc);
    setCsvFileName(`รายงานรายได้ชวนเพื่อน_งวด_${selectedRoundDate}.csv`);
  };

  return (
    <div>
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>
      {/* rounddate select */}
      <div className="bg-white w-full flex gap-3 items-center p-8 rounded-lg drop-shadow-xl">
        <div className="flex items-center gap-2">
          <p className="text-md font-bold">งวด</p>
          <Select
            value={selectedRoundDate}
            onValueChange={(e) => setRoundDate(e)}
          >
            <SelectTrigger className="my-1 w-48 text-md font-medium">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-black">
              {configs.roundDates.reverse().map((roundDate) => {
                return (
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value={roundDate}
                    key={roundDate}
                  >
                    {roundDate}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
        <div className="flex gap-2">
          <button
            className={`h-10 w-24 bg-[#168AFF] flex justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50 ${
              (data.length === 0 || !data) && "hidden"
            }`}
            onClick={() => handleDownload()}
          >
            <HiOutlineDownload className="w-5 h-5 mr-2" />
            CSV
          </button>
        </div>
      </div>
      <div className="mb-24 mt-3">
        {isLoading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <Loading className={`h-4 w-4`} />
          </div>
        ) : (
          data &&
          (data.length > 0 ? (
            <div className="flex justify-start flex-col gap-3">
              {Object.entries(sortedData).map(([id, orders], index) => {
                return <Affiliate id={id} orders={orders} index={index} />;
              })}
            </div>
          ) : (
            <p className="text-gray-600 text-center text-xl font-semibold py-48">
              ไม่พบคำสั่งซื้อ
            </p>
          ))
        )}
      </div>
    </div>
  );
};

const Affiliate = ({ orders, id, index }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={`flex gap-3 py-3  `}>
      <div className="w-20">
        <p className="text-sm">ลำดับที่</p>
        <p className="w-fit font-semibold text-4xl hover:text-[rgb(59,176,101)] duration-150 hover:scale-95 cursor-pointer">
          {index + 1}
        </p>
      </div>
      <div className="w-1/5">
        <p className="text-sm">ผู้แนะนำ</p>
        <p
          className="w-fit font-semibold text-4xl hover:text-[rgb(59,176,101)] duration-150 hover:scale-95 cursor-pointer"
          onClick={async () => {
            window.open(
              // constant.webConfigs.customerUrl +
              "https://admin.hongthongofficial.com/users?" + id
            );
          }}
        >
          {id}
        </p>
      </div>
      <div className={`flex-grow w-full flex flex-col`}>
        <div className="grid grid-cols-4">
          <div className="">
            <p className="text-sm">จำนวนออเดอร์</p>
            <p className="w-fit font-semibold text-4xl">
              {orders.length}{" "}
              <span className="text-xs font-medium">ออเดอร์</span>
            </p>
          </div>
          <div className="">
            <p className="text-sm">รวมทั้งหมด</p>
            <p className="w-fit font-semibold text-4xl">
              {orders.reduce((acc, order) => {
                acc += order.size;
                return acc;
              }, 0)}{" "}
              <span className="text-xs font-medium">ใบ</span>
            </p>
          </div>
          <div></div>
          <div className="flex justify-end items-center">
            <div
              onClick={() => setOpen(!isOpen)}
              className="bg-gray-950 hover:scale-95 duration-150 w-10 h-10 p-2 cursor-pointer flex justify-center items-center rounded-lg"
            >
              <p className="text-2xl text-gray-50 text-right font-bold">
                {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </p>
            </div>
          </div>
        </div>
        <Collapse isOpen={isOpen}>
          {orders.map((order, index) => {
            return (
              <div key={order.shortId}>
                <div className="my-3  grid grid-cols-4" key={order.shortId}>
                  <div className="">
                    <p className="text-sm">ไอดี</p>
                    <p
                      className="w-fit font-semibold text-4xl hover:text-[#ffb067] duration-150 hover:scale-95 cursor-pointer"
                      onClick={async () => {
                        window.open(
                          // constant.webConfigs.customerUrl +
                          "https://admin.hongthongofficial.com/orders/" +
                            order.id
                        );
                      }}
                    >
                      {order.shortId}
                    </p>
                  </div>
                  <div className="">
                    <p className="text-sm">ผู้ซื้อ</p>
                    <p
                      className="w-fit font-semibold text-4xl hover:text-[rgb(59,176,101)] duration-150 hover:scale-95 cursor-pointer"
                      onClick={async () => {
                        window.open(
                          // constant.webConfigs.customerUrl +
                          "https://admin.hongthongofficial.com/users?" +
                            order.userId
                        );
                      }}
                    >
                      {order.userId}
                    </p>
                  </div>
                  <div className="">
                    <p className="text-sm">จำนวน</p>
                    <p className="w-fit font-semibold text-4xl duration-150 hover:scale-95 cursor-pointer">
                      {order.size}{" "}
                      <span className="text-xs font-medium">ใบ</span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
};
