import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import { CSVLink } from "react-csv";
import { Loading } from "../../components/modules";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";
import { unixToDate, getThaiDateAndTimeFormat } from "../../util";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { HiOutlineDownload } from "react-icons/hi";
import { Separator } from "@radix-ui/react-select";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

export const Complain = () => {
  const { callApi, configs } = Context();

  const [isLoading, setLoading] = useState(false);
  const [selectedRoundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState(false);

  useEffect(() => {
    handleSearch();
  }, [selectedRoundDate]);

  const handleSearch = async () => {
    setLoading(true);

    let res = await callApi("complains-getAll", {
      limit: 10,
      orderBy: ["createdAt", "desc"],
    });
    console.log("🚀 ~ handleSearch ~ res:", res);

    setLoading(false);
    setData(res);
  };

  return (
    <div>
      {/* rounddate select */}
      <div className="bg-white w-full flex gap-3 items-center p-8 rounded-lg drop-shadow-xl">
        <div className="flex items-center gap-2">
          <p className="text-md font-bold">งวด</p>
          <Select
            value={selectedRoundDate}
            onValueChange={(e) => setRoundDate(e)}
          >
            <SelectTrigger className="my-1 w-48 text-md font-medium">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-black">
              {configs.roundDates.reverse().map((roundDate) => {
                return (
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value={roundDate}
                    key={roundDate}
                  >
                    {roundDate}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="mb-24 mt-3">
        {isLoading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <Loading className={`h-4 w-4`} />
          </div>
        ) : (
          data &&
          (data.length > 0 ? (
            <div className="flex justify-start flex-col gap-3">
              {data.map((complain, index) => {
                return <ComplainContainer complain={complain} index={index} />;
              })}
            </div>
          ) : (
            <p className="text-gray-600 text-center text-xl font-semibold py-48">
              ไม่พบคำสั่งซื้อ
            </p>
          ))
        )}
      </div>
    </div>
  );
};

const ComplainContainer = ({ complain, index }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={` gap-3 py-3  bg-white p-8 rounded-lg drop-shadow-xl`}>
      <div className="grid grid-cols-6">
        <div className="col-span-2">
          <p className="text-sm">วันที่-เวลา</p>
          <p className="w-fit font-semibold">
            {new Date(complain?.createdAt).toLocaleDateString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </p>
        </div>
        <div className="">
          <p className="text-sm">ชื่อ-นามสกุล</p>
          <p className="w-fit font-semibold cursor-pointer">
            {complain.firstName + " " + complain.lastName}
          </p>
        </div>
        <div className="">
          <p className="text-sm">เบอร์โทรศัพท์</p>
          <p className="w-fit font-semibold cursor-pointer">{complain.phone}</p>
        </div>
        <div className="">
          <p className="text-sm">รหัสผู้ใช้</p>
          <p className="w-fit font-semibold cursor-pointer">
            {complain.userId || "-"}
          </p>
        </div>
        <div className="">
          <p className="text-sm">สถานะ</p>
          <p className="w-fit font-semibold cursor-pointer">
            {complain.status || "-"}
          </p>
        </div>
      </div>
    </div>
  );
};
