import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import ButtonLoading from "../../components/ButtonLoading";
import { Table, Input, Badge } from "reactstrap";
import { Flex, Label } from "../../components";
import { thaiDate } from "../../util";
import { isNil, isEmpty } from "ramda";
import styled from "styled-components";
import themes from "../../themes";
import { GridItem } from "../dashboard/Overall";
const LotteriesImported = () => {
  const { callApi, profileState, go, configs } = Context();
  const [unimported, setUnimported] = useState({});
  const [fileNameId, setFileNameId] = useState("");
  const [filteredData, setFilteredData] = useState({});
  const [totalLotto, setTotalLotto] = useState(0);
  const [totalUnImported, setTotalUnImported] = useState(0);

  const getData = async () => {
    const data = await callApi("lotteries-countImports", {
      isOnBlock: true,
    }).then((result) => {
      return result.data;
    });
    localStorage.setItem("lotto-import", JSON.stringify(data));
    setUnimported(data || {});
  };

  const getFilteredData = async () => {
    const data = await callApi("lotteries-getAll", {
      queries: [
        ["fileNameId", "==", fileNameId],
        ["isOnBlock", "==", true],
        ["roundDate", "==", configs.roundDate],
      ],

      limit: 200,
    });

    const getData = data.reduce(
      (acc, lotto) => {
        if (lotto.isSold) {
          acc["isSold"] += 1;
        }

        acc["length"] += 1;

        if (!acc["uploader"].includes(lotto.uploader)) {
          acc["uploader"].push(lotto.uploader);
        }
        return acc;
      },
      {
        fileNameId: fileNameId,
        length: 0,
        isSold: 0,

        uploader: [],
      }
    );
    if (getData.length <= 0) {
      setFilteredData("error");
      return;
    }
    setFilteredData(getData);
  };

  useEffect(() => {
    const lottoImport = JSON.parse(localStorage.getItem("lotto-import"));
    if (!lottoImport) {
      return getData();
    }
    setUnimported(lottoImport);
    setupDashBoard();
  }, []);

  const getBadgeColor = (value) => {
    if (Number(value) === 100) {
      return "success";
    } else if (Number(value) >= 90) {
      return "info";
    }
  };

  const setupDashBoard = () => {
    callApi("utils-getDashboard").then(({ data }) => {
      if (!data.updatedAt) return;
      setTotalLotto(
        (data["lottery-unimported"] + data["lottery-imported"]).toLocaleString()
      );

      setTotalUnImported(data["lottery-unimported"]?.toLocaleString());
    });
  };

  return (
    <>
      <button onClick={getData}>Refresh</button>
      <Label.H5 margin="0 1em 0 0" color="red">
        * การดึงข้อมูลทั้งหมดใช้เวลานานมาก หากไม่จำเป็นให้ค้นหาชื่อไฟล์แทน
      </Label.H5>

      <Flex margin="3em 0" align="center" width="100%">
        <Flex direction="column">
          <div style={{ width: "100%", display: "flex" }}>
            <Label.H2 margin="0 1em 0 0" weight={500}>
              จำนวนเลขที่พร้อมขายทั้งหมด{" "}
              {Object.values(unimported)
                .filter((x) => x.newLotteries !== 0)
                .length.toLocaleString()}{" "}
              เล่ม (
              {Object.values(unimported)
                .reduce((acc, x) => {
                  return acc + x.size;
                }, 0)
                .toLocaleString()}
              ) ใบ
            </Label.H2>
          </div>
          <Label.H5 margin="0 1em 0 0" color="red">
            *** หากไม่เจอไฟล์ในตาราง ให้ค้นหาโดยชื่อไฟล์
          </Label.H5>
          <br />
          <GridContainer>
            <GridItem
              topLabel="จำนวนสลากทั้งหมดในระบบ"
              bottomLabel="ใบ"
              value={totalLotto}
              color={themes.colors.fadeBlue}
              maxWidth={"500px"}
            />

            <GridItem
              topLabel="จำนวนสลากในระบบหลังบ้าน"
              bottomLabel="ใบ"
              value={totalUnImported}
              color={themes.colors.fadeRed}
              maxWidth="500px"
            />
          </GridContainer>

          <br />

          <Flex align="center">
            <Label.H5 margin="0 1em 0 0" weight={500}>
              ค้นหาข้อมูลโดยชื่อเล่ม
            </Label.H5>

            <Input
              name="fileNameId"
              type="text"
              placeholder="0000-100-zip"
              value={fileNameId}
              onChange={(e) => setFileNameId(e.target.value)}
              style={{ width: "200px" }}
            />

            <button
              disabled={!fileNameId}
              style={{ margin: "0 10px " }}
              onClick={() => getFilteredData()}
            >
              ค้นหา
            </button>
            <button
              disabled={!fileNameId}
              style={{ margin: "0 10px " }}
              onClick={() => {
                setFileNameId("");
                setFilteredData({});
              }}
            >
              รีเซ็ต
            </button>
          </Flex>
        </Flex>
      </Flex>
      <FullScrollBar>
        <Table striped hover>
          <thead>
            <tr>
              <th className="align-middle text-center"></th>
              <th className="align-middle text-center">ดูข้อมูล</th>
              <th className="align-middle text-center">ลำดับที่</th>
              <th className="align-middle text-center">ชื่อไฟล์</th>
              <th className="align-middle text-center">
                จำนวนเลขในระบบหน้าบ้าน
              </th>
              <th className="align-middle text-center">ขายไปแล้ว (ใบ)</th>
              <th className="align-middle text-center">ขายไปแล้ว (%)</th>
              <th className="align-middle text-center">นำเข้าโดย</th>
              {/* <th /> */}
            </tr>
          </thead>
          <tbody>
            {!isEmpty(filteredData) ? (
              filteredData === "error" ? (
                <>
                  <br />
                  <h1>ไม่พบข้อมูล</h1>
                </>
              ) : (
                <tr>
                  <td className="align-middle text-center">
                    <button
                      onClick={() =>
                        window.open(
                          `/lotteries/detail?fileNameId=${filteredData.fileNameId}`,
                          "_blank"
                        )
                      }
                    >
                      ดูข้อมูล
                    </button>
                  </td>
                  <td className="align-middle text-center">1</td>
                  <td className="align-middle text-center">
                    {filteredData.fileNameId}
                  </td>
                  <td className="align-middle text-center">
                    {filteredData.length}
                  </td>
                  <td className="align-middle text-center">
                    <Badge>{filteredData.isSold}</Badge>
                  </td>

                  <td className="align-middle text-center">
                    {filteredData.uploader.map((x) => x + " ")}
                  </td>
                </tr>
              )
            ) : (
              Object.entries(unimported)
                .sort(([key1, value1], [key2, value2]) =>
                  key1.localeCompare(key2, undefined, {
                    numeric: true,
                  })
                )
                .map(([key, value], index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle text-center">
                        <button
                          onClick={async () =>
                            await callApi("blocks-pullLottoByFilenameId", {
                              fileNameId: key,
                            })
                          }
                        >
                          นำออกจากระบบ
                        </button>
                      </td>
                      <td className="align-middle text-center">
                        <button
                          onClick={() =>
                            window.open(
                              `/lotteries/detail?fileNameId=${key}`,
                              "_blank"
                            )
                          }
                        >
                          ดูข้อมูล
                        </button>
                      </td>
                      <td className="align-middle text-center">{index + 1}</td>
                      <td className="align-middle text-center">{key}</td>
                      <td className="align-middle text-center">{value.size}</td>
                      <td className="align-middle text-center">
                        <Badge
                          color={getBadgeColor(
                            (value.isSold / value.size) * 100
                          )}
                        >
                          {value.isSold}
                        </Badge>
                      </td>
                      <td className="align-middle text-center">
                        <Badge
                          color={getBadgeColor(
                            (value.isSold / value.size) * 100
                          )}
                        >
                          {((value.isSold / value.size) * 100).toFixed(2)}
                        </Badge>
                      </td>
                      <td className="align-middle text-center">
                        {value.uploader}
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </Table>
      </FullScrollBar>
      <br />
    </>
  );
};
const GridContainer = styled.div`
  display: grid;
  /* grid-template-columns: auto auto auto; */
  /* grid-template-columns: repeat(auto-fit, minmax(32%, max-content)); */
  grid-template-columns: auto auto auto auto;
  gap: 1.25rem;

  @media only screen and (max-width: 1368px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 1124px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 987px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 150px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const FullScrollBar = styled(ScrollBar)`
  width: 100% !important;
  max-width: 100% !important;
  max-height: 70vh;
`;
export default LotteriesImported;
