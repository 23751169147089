import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Badge } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";

import constansts from "../../constants";

export const ReportUser = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const handleSearch = async () => {
    const data = await callApi("coupons-getAllTransaction", {
      queries: [
        ["status", "==", constansts.statuses.APPROVE],
        ["roundDate", "==", roundDate],
      ],
      startAfter: "",
    });
    const sumPrice = data.reduce((acc, couponOrder) => {
      acc += couponOrder.price;
      return acc;
    }, 0);
    setTotalPrice(sumPrice);
    setData(data);
  };

  const handleDownload = async () => {
    const allUserData = [];
    let keepGoing = true;
    let currentIndex = 0;
    let startAtId = "";
    let limit = 10000;

    while (keepGoing) {
      console.log(`calling index ${currentIndex}-${currentIndex + limit}`);
      const data = await callApi("users-getAll", {
        limit: limit,
        startAfter: startAtId,
      });

      if (data.length < limit || !data) {
        console.log("stop");
        keepGoing = false;
      }

      console.log(`fetching size: ${data.length}`);
      console.log("id", data[data.length - 1].id);
      startAtId = data[data.length - 1].id;
      allUserData.push(...data);
      console.log(`allUserData size: ${allUserData.length}`);
      currentIndex += limit;
    }

    console.log(allUserData.length);

    const acc = [];
    acc.push(["ลำดับที่", "ชื่อ", "นามสกุล", "เบอร์"]);

    await allUserData.map((couponData, index) => {
      acc.push([
        index + 1,
        couponData.firstName,
        couponData.lastName,
        couponData.phone,
      ]);
    });

    setCsvData(acc);
    setCsvFileName(`รายงานผู้ใช้งานงวด ${roundDate}.csv`);
  };

  return (
    <Container>
      <Label.H2 weight={500}>รายงานผู้ใช้</Label.H2>

      <br />

      <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
        Download Excel
      </Button>

      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
    </Container>
  );
};

const ScrollBar = styled.div`
  max-height: 150px;
  max-width: 250px;

  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 194, 64, 1);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
const Button = styled.button``;
