import * as React from "react";
import Context from "../../Context";
import { cn } from "../../util/lib";

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  const { callApi, isDarkTheme, themes } = Context();
  return (
    <input
      type={type}
      className={cn(
        "flex h-12 my-1 w-full rounded-[0.5rem]  border border-input bg-transparent px-3 py-1 text-md font-medium focus:shadow-sm focus:outline-1 focus:outline-[rgb(214,212,212)] invalid:cursor-not-allowed transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:cursor-not-allowed disabled:opacity-50",
        isDarkTheme
          ? "text-white placeholder:text-[rgb(255,255,255,0.6)]"
          : "placeholder:text-gray-300",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

Input.displayName = "Input";

export { Input };
