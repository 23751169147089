import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";

import {
  Sheet,
  SheetContent,
  SheetTrigger,
  Switch,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import { ScrollArea } from "../../components/ui";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate, bankTranslater } from "../../util";
import constant from "../../constants";
import { Loading } from "../../components/modules";
import constansts from "../../constants";

export const Winner = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [selectedRoundDate, setRoundDate] = useState(configs.roundDate);
  const [data, setData] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentChannel, setPaymentChannel] = useState("bank");
  const [isGuest, setIsGuest] = useState(false);
  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState({
    all: false,
    kbank: false,
    scb: false,
    ktb: false,
    bbl: false,
  });
  const nonScb = [
    "ธนาคารกรุงศรีอยุธยา",
    "ธนาคารธนชาต",
    "ธนาคารทิสโก้",
    "ธนาคารออมสิน",
    "ธนาคารอาคารสงเคราะห์",
    "ธนาคารอิสลามแห่งประเทศไทย",
    "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    "ธนาคารทหารไทยธนชาต",
    "ธนาคารซีไอเอ็มบีไทย",
    "ธนาคารยูโอบี",
    "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    "ธนาคารเกียรตินาคินภัทร",
  ];
  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const handleSearch = async () => {
    setLoading(true);

    let res = await callApi("userPrizes-getAll", {
      limit: 10000,
      startAfter: "",
      queries: [["roundDate", "==", selectedRoundDate]],
      roundDate: selectedRoundDate,
    });

    const filteredData = res.reduce((acc, data) => {
      const { bankName, accNo, accName } = data;

      acc[bankName] ? acc[bankName].push(data) : (acc[bankName] = [data]);

      return acc;
    }, {});

    console.log("🚀 ~ filteredData ~ filteredData:", filteredData);

    setLoading(false);
    setData(res);
  };

  const handleDownload = async () => {
    const acc = [];
    acc.push([
      "ลำดับที่",
      "วันที่ซื้อ",
      "รหัสออเดอร์",
      "ราคา",
      "ชื่อ-นามสกุล",
      "เลขอ้างอิง",
    ]);

    await data.map((order, index) => {
      acc.push([
        index + 1,
        order.createdAtDate,
        order.shortId,
        order.totalPrice?.toLocaleString(),
        order?.firstName + " " + order?.lastName,
        order.slipId || "-",
      ]);
    });

    setCsvData(acc);
    setCsvFileName(`รายงานยอดขาย​  ${selectedRoundDate}.csv`);
  };

  return (
    <div className="bg-white w-full mb-24  p-8 rounded-lg drop-shadow-xl">
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>

      <div className=" ">
        <div className="mb-3 flex gap-2">
          <div>
            <p className="text-md font-bold">งวด</p>
            <Select
              value={selectedRoundDate}
              onValueChange={(e) => setRoundDate(e)}
            >
              <SelectTrigger className="my-1 w-48 text-md font-medium">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-black">
                {configs.roundDates.reverse().map((roundDate) => {
                  return (
                    <SelectItem
                      className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                      value={roundDate}
                      key={roundDate}
                    >
                      {roundDate}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="flex gap-2">
          <button
            className="h-12 w-32 bg-gray-950 hover:shadow-xl duration-200  disabled:hover:bg-gray-950  text-white font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50"
            onClick={handleSearch}
          >
            {isLoading ? <Loading className={`h-4 w-4`} /> : "ค้นหา"}
          </button>

          <button
            className={`h-12 w-48 bg-[#37beb0]  text-white hover:shadow-xl duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50 ${
              (data.length === 0 || !data) && "hidden"
            }`}
            onClick={() => handleDownload()}
          >
            ดาวน์โหลด
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex w-full justify-center my-48">
          <Loading />
        </div>
      ) : (
        data &&
        (data.length > 0 ? (
          <>
            <div className="my-12">
              <div className="flex gap-2">
                <div
                  className="py-2 max-w-60 px-3 w-full col-span-2 xl:col-span-1 bg-gray-950 text-white rounded-lg hover:cursor-pointer hover:scale-[98%] duration-300"
                  // onClick={() => handleClickSummary("", "")}
                >
                  <p className="text-sm font-medium"> จำนวนรางวัลทั้งหมด</p>
                  <div className="text-2xl text-center mt-3 mb-4 font-bold">
                    {data.length}
                  </div>
                  <p className="text-sm text-right font-medium">ออเดอร์</p>
                </div>
                <div
                  className="py-2 max-w-60 px-3 w-full bg-[rgb(59,176,101)] text-white rounded-lg hover:cursor-pointer hover:scale-[98%]  duration-300"
                  // onClick={() => handleClickSummary("status", "approved")}
                >
                  <p className="text-sm font-semibold">KBANK</p>
                  <div className="text-2xl text-center mt-3 mb-4 font-bold">
                    {data
                      .filter((x) => x.bankName === "ธนาคารกสิกรไทย")
                      .length.toLocaleString()}
                  </div>
                  <p className="text-sm text-right font-medium">คน</p>
                </div>
                <div
                  className="py-2 max-w-60 px-3 w-full bg-[#5E3881] text-white rounded-lg hover:cursor-pointer hover:scale-[98%]  duration-300"
                  // onClick={() => handleClickSummary("status", "approved")}
                >
                  <p className="text-sm font-semibold">SCB</p>
                  <div className="text-2xl text-center mt-3 mb-4 font-bold">
                    {data
                      .filter((x) => x.bankName === "ธนาคารไทยพาณิชย์")
                      .length.toLocaleString()}
                  </div>
                  <p className="text-sm text-right font-medium">คน</p>
                </div>
                <div
                  className="py-2 max-w-60 px-3 w-full bg-[#05A2E0] text-white rounded-lg hover:cursor-pointer hover:scale-[98%]  duration-300"
                  // onClick={() => handleClickSummary("status", "approved")}
                >
                  <p className="text-sm font-semibold">KTB</p>
                  <div className="text-2xl text-center mt-3 mb-4 font-bold">
                    {data
                      .filter((x) => x.bankName === "ธนาคารกรุงไทย")
                      .length.toLocaleString()}
                  </div>
                  <p className="text-sm text-right font-medium">คน</p>
                </div>
                <div
                  className="py-2 max-w-60 px-3 w-full bg-[#0E1B80] text-white rounded-lg hover:cursor-pointer hover:scale-[98%]  duration-300"
                  // onClick={() => handleClickSummary("status", "approved")}
                >
                  <p className="text-sm font-semibold">BBL</p>
                  <div className="text-2xl text-center mt-3 mb-4 font-bold">
                    {data
                      .filter((x) => x.bankName === "ธนาคารกรุงเทพฯ")
                      .length.toLocaleString()}
                  </div>
                  <p className="text-sm text-right font-medium">คน</p>
                </div>
                <div
                  className="py-2 max-w-60 px-3 w-full bg-[#592941] text-white rounded-lg hover:cursor-pointer hover:scale-[98%]  duration-300"
                  // onClick={() => handleClickSummary("status", "approved")}
                >
                  <p className="text-sm font-semibold">NON_SCB</p>
                  <div className="text-2xl text-center mt-3 mb-4 font-bold">
                    {data
                      .filter((x) => nonScb.some((item) => item === x.bankName))
                      .length.toLocaleString()}
                  </div>
                  <p className="text-sm text-right font-medium">คน</p>
                </div>
              </div>
            </div>
            {/* all */}
            <div
              onClick={() =>
                setShow((prevState) => ({ ...prevState, all: !prevState.all }))
              }
              className="border-2 rounded-lg border-gray-950 overflow-hidden cursor-pointer"
            >
              <div className="bg-gray-950  text-white p-3 flex justify-between">
                <p className="font-semibold">ทั้งหมด {data.length} รางวัล </p>
                <p className="font-semibold">
                  รางวัลรวมมูลค่า{" "}
                  {data
                    .reduce((acc, prize) => {
                      acc += prize.totalPrizes;

                      return acc;
                    }, 0)
                    .toLocaleString()}{" "}
                  บาท{" "}
                </p>
              </div>
              <div
                className={`${show.all ? "h-[400px]" : "h-0"} duration-150 `}
              >
                <div className={`grid grid-cols-11 py-3 gap-3 rounded-lg`}>
                  <div className="flex justify-center items-center">
                    <p className="text-center text-muted-foreground font-medium">
                      ลำดับที่
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground  font-medium">ธนาคาร</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground text-left font-medium">
                      ชื่อบัญชี
                    </p>
                  </div>

                  <div className="flex justify-start items-center col-span-2">
                    <p className="text-muted-foreground font-medium">
                      ชื่อ - นามสกุล
                    </p>
                  </div>

                  <div className="flex justify-end items-center">
                    <p className="text-muted-foreground text-right font-medium">
                      จำนวนเงิน
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เลขอ้างอิง
                    </p>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="text-muted-foreground font-medium">
                      ค่าธรรมเนียม
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      รหัสสาขา
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เบอร์โทรศัพท์
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">อีเมล์</p>
                  </div>
                </div>
                <ScrollArea className={`h-[352px] w-full relative`}>
                  {data
                    .sort((a, b) => a.bankName - b.bankName)
                    .map((winner, index) => {
                      return (
                        <div
                          className={`grid gap-3 grid-cols-11 rounded-sm border-t mr-2 py-3`}
                          key={index}
                        >
                          <div>
                            <p className="text-center font-semibold">
                              {index + 1}
                            </p>
                          </div>
                          <div className="">
                            <p className="font-medium truncate">
                              {bankTranslater(winner.bankName) || ""}
                            </p>
                          </div>
                          <div>
                            <p className="text-gray-900 font-semibold truncate">
                              {winner.accNo.split("-").join("")}
                            </p>
                          </div>
                          <div className="col-span-2">
                            <p className="truncate">
                              {winner?.firstName + " " + winner?.lastName}
                            </p>
                          </div>
                          <div className="">
                            <p className="text-right font-semibold">
                              {winner.totalPrizes?.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-left">{winner.userId}</p>
                          </div>
                          <div className="">
                            <p className="text-left">Payer (OUR)</p>
                          </div>
                          <div>
                            <p className="text-left">0001</p>
                          </div>
                          <div className="">
                            <p className="text-left">{winner.phone}</p>
                          </div>
                          <div>
                            <p className="truncate text-left">
                              {winner.email || "paranee.t@vproperty.co.th"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </ScrollArea>
              </div>
            </div>
            {/* kbank */}
            <div
              onClick={() =>
                setShow((prevState) => ({
                  ...prevState,
                  kbank: !prevState.kbank,
                }))
              }
              className="border-2 rounded-lg border-[rgb(59,176,101)] mt-4 overflow-hidden cursor-pointer"
            >
              <div className="bg-[rgb(59,176,101)]  text-white p-3 flex justify-between">
                <p className="font-semibold">
                  KBANK{" "}
                  {data.filter((x) => x.bankName === "ธนาคารกสิกรไทย").length}{" "}
                  รางวัล
                </p>
                <p className="font-semibold">
                  รางวัลรวมมูลค่า{" "}
                  {data
                    .filter((x) => x.bankName === "ธนาคารกสิกรไทย")
                    .reduce((acc, prize) => {
                      acc += prize.totalPrizes;

                      return acc;
                    }, 0)
                    .toLocaleString()}{" "}
                  บาท{" "}
                </p>
              </div>
              <div
                className={`${show.kbank ? "h-[400px]" : "h-0"} duration-150 `}
              >
                <div
                  className={`grid grid-cols-11 py-3 gap-3 relative rounded-lg`}
                >
                  <div className="flex justify-center items-center">
                    <p className="text-center text-muted-foreground font-medium">
                      ลำดับที่
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground  font-medium">ธนาคาร</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground text-left font-medium">
                      ชื่อบัญชี
                    </p>
                  </div>

                  <div className="flex justify-start items-center col-span-2">
                    <p className="text-muted-foreground font-medium">
                      ชื่อ - นามสกุล
                    </p>
                  </div>

                  <div className="flex justify-end items-center">
                    <p className="text-muted-foreground text-right font-medium">
                      จำนวนเงิน
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เลขอ้างอิง
                    </p>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="text-muted-foreground font-medium">
                      ค่าธรรมเนียม
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      รหัสสาขา
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เบอร์โทรศัพท์
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">อีเมล์</p>
                  </div>
                </div>
                <ScrollArea className={`h-[352px] w-full relative`}>
                  {data
                    .filter((x) => x.bankName === "ธนาคารกสิกรไทย")

                    .map((winner, index) => {
                      return (
                        <div
                          className={`grid gap-3 grid-cols-11 rounded-sm border-t mr-2 py-3`}
                          key={index}
                        >
                          <div>
                            <p className="text-center font-semibold">
                              {index + 1}
                            </p>
                          </div>
                          <div className="">
                            <p className="font-medium truncate">
                              {bankTranslater(winner.bankName) || ""}
                            </p>
                          </div>
                          <div>
                            <p className="text-gray-900 font-semibold truncate">
                              {winner.accNo.split("-").join("")}
                            </p>
                          </div>
                          <div className="col-span-2">
                            <p className="truncate">
                              {winner?.firstName + " " + winner?.lastName}
                            </p>
                          </div>
                          <div className="">
                            <p className="text-right font-semibold">
                              {winner.totalPrizes?.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-left">{winner.userId}</p>
                          </div>
                          <div className="">
                            <p className="text-left">Payer (OUR)</p>
                          </div>
                          <div>
                            <p className="text-left">0001</p>
                          </div>
                          <div className="">
                            <p className="text-left">{winner.phone}</p>
                          </div>
                          <div>
                            <p className="truncate text-left">
                              {winner.email || "paranee.t@vproperty.co.th"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </ScrollArea>
              </div>
            </div>
            {/* scb */}
            <div
              onClick={() =>
                setShow((prevState) => ({
                  ...prevState,
                  scb: !prevState.scb,
                }))
              }
              className="border-2 mt-8 rounded-lg border-[#5E3881] overflow-hidden cursor-pointer"
            >
              <div className="bg-[#5E3881]  text-white p-3 flex justify-between">
                <p className="font-semibold">
                  SCB{" "}
                  {data.filter((x) => x.bankName === "ธนาคารไทยพาณิชย์").length}{" "}
                  รางวัล
                </p>
                <p className="font-semibold">
                  รางวัลรวมมูลค่า{" "}
                  {data
                    .filter((x) => x.bankName === "ธนาคารไทยพาณิชย์")
                    .reduce((acc, prize) => {
                      acc += prize.totalPrizes;

                      return acc;
                    }, 0)
                    .toLocaleString()}{" "}
                  บาท{" "}
                </p>
              </div>
              <div
                className={`${show.scb ? "h-[400px]" : "h-0"} duration-150 `}
              >
                <div className={`grid grid-cols-11 py-3 gap-3 rounded-lg`}>
                  <div className="flex justify-center items-center">
                    <p className="text-center text-muted-foreground font-medium">
                      ลำดับที่
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground  font-medium">ธนาคาร</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground text-left font-medium">
                      ชื่อบัญชี
                    </p>
                  </div>

                  <div className="flex justify-start items-center col-span-2">
                    <p className="text-muted-foreground font-medium">
                      ชื่อ - นามสกุล
                    </p>
                  </div>

                  <div className="flex justify-end items-center">
                    <p className="text-muted-foreground text-right font-medium">
                      จำนวนเงิน
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เลขอ้างอิง
                    </p>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="text-muted-foreground font-medium">
                      ค่าธรรมเนียม
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      รหัสสาขา
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เบอร์โทรศัพท์
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">อีเมล์</p>
                  </div>
                </div>
                <ScrollArea className={`h-[352px] w-full relative`}>
                  {data
                    .filter((x) => x.bankName === "ธนาคารไทยพาณิชย์")

                    .map((winner, index) => {
                      return (
                        <div
                          className={`grid gap-3 grid-cols-11 rounded-sm border-t mr-2 py-3`}
                          key={index}
                        >
                          <div>
                            <p className="text-center font-semibold">
                              {index + 1}
                            </p>
                          </div>
                          <div className="">
                            <p className="font-medium truncate">
                              {bankTranslater(winner.bankName) || ""}
                            </p>
                          </div>
                          <div>
                            <p className="text-gray-900 font-semibold truncate">
                              {winner.accNo.split("-").join("")}
                            </p>
                          </div>
                          <div className="col-span-2">
                            <p className="truncate">
                              {winner?.firstName + " " + winner?.lastName}
                            </p>
                          </div>
                          <div className="">
                            <p className="text-right font-semibold">
                              {winner.totalPrizes?.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-left">{winner.userId}</p>
                          </div>
                          <div className="">
                            <p className="text-left">Payer (OUR)</p>
                          </div>
                          <div>
                            <p className="text-left">0001</p>
                          </div>
                          <div className="">
                            <p className="text-left">{winner.phone}</p>
                          </div>
                          <div>
                            <p className="truncate text-left">
                              {winner.email || "paranee.t@vproperty.co.th"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </ScrollArea>
              </div>
            </div>
            {/* ktb */}
            <div
              onClick={() =>
                setShow((prevState) => ({
                  ...prevState,
                  ktb: !prevState.ktb,
                }))
              }
              className="border-2 mt-8 rounded-lg border-[#05A2E0] overflow-hidden cursor-pointer"
            >
              <div className="bg-[#05A2E0]  text-white p-3 flex justify-between">
                <p className="font-semibold">
                  KTB{" "}
                  {data.filter((x) => x.bankName === "ธนาคารกรุงไทย").length}{" "}
                  รางวัล
                </p>
                <p className="font-semibold">
                  รางวัลรวมมูลค่า{" "}
                  {data
                    .filter((x) => x.bankName === "ธนาคารกรุงไทย")
                    .reduce((acc, prize) => {
                      acc += prize.totalPrizes;

                      return acc;
                    }, 0)
                    .toLocaleString()}{" "}
                  บาท{" "}
                </p>
              </div>
              <div
                className={`${show.ktb ? "h-[400px]" : "h-0"} duration-150 `}
              >
                <div className={`grid grid-cols-11 py-3 gap-3 rounded-lg`}>
                  <div className="flex justify-center items-center">
                    <p className="text-center text-muted-foreground font-medium">
                      ลำดับที่
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground  font-medium">ธนาคาร</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground text-left font-medium">
                      ชื่อบัญชี
                    </p>
                  </div>

                  <div className="flex justify-start items-center col-span-2">
                    <p className="text-muted-foreground font-medium">
                      ชื่อ - นามสกุล
                    </p>
                  </div>

                  <div className="flex justify-end items-center">
                    <p className="text-muted-foreground text-right font-medium">
                      จำนวนเงิน
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เลขอ้างอิง
                    </p>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="text-muted-foreground font-medium">
                      ค่าธรรมเนียม
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      รหัสสาขา
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เบอร์โทรศัพท์
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">อีเมล์</p>
                  </div>
                </div>
                <ScrollArea className={`h-[352px] w-full relative`}>
                  {data
                    .filter((x) => x.bankName === "ธนาคารกรุงไทย")

                    .map((winner, index) => {
                      return (
                        <div
                          className={`grid gap-3 grid-cols-11 rounded-sm border-t mr-2 py-3`}
                          key={index}
                        >
                          <div>
                            <p className="text-center font-semibold">
                              {index + 1}
                            </p>
                          </div>
                          <div className="">
                            <p className="font-medium truncate">
                              {bankTranslater(winner.bankName) || ""}
                            </p>
                          </div>
                          <div>
                            <p className="text-gray-900 font-semibold truncate">
                              {winner.accNo.split("-").join("")}
                            </p>
                          </div>
                          <div className="col-span-2">
                            <p className="truncate">
                              {winner?.firstName + " " + winner?.lastName}
                            </p>
                          </div>
                          <div className="">
                            <p className="text-right font-semibold">
                              {winner.totalPrizes?.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-left">{winner.userId}</p>
                          </div>
                          <div className="">
                            <p className="text-left">Payer (OUR)</p>
                          </div>
                          <div>
                            <p className="text-left">0001</p>
                          </div>
                          <div className="">
                            <p className="text-left">{winner.phone}</p>
                          </div>
                          <div>
                            <p className="truncate text-left">
                              {winner.email || "paranee.t@vproperty.co.th"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </ScrollArea>
              </div>
            </div>
            {/* bbl */}
            <div
              onClick={() =>
                setShow((prevState) => ({
                  ...prevState,
                  bbl: !prevState.bbl,
                }))
              }
              className="border-2 mt-8 rounded-lg border-[#0E1B80] overflow-hidden cursor-pointer"
            >
              <div className="bg-[#0E1B80]  text-white p-3 flex justify-between">
                <p className="font-semibold">
                  BBL{" "}
                  {data.filter((x) => x.bankName === "ธนาคารกรุงเทพฯ").length}{" "}
                  รางวัล
                </p>
                <p className="font-semibold">
                  รางวัลรวมมูลค่า{" "}
                  {data
                    .filter((x) => x.bankName === "ธนาคารกรุงเทพฯ")
                    .reduce((acc, prize) => {
                      acc += prize.totalPrizes;

                      return acc;
                    }, 0)
                    .toLocaleString()}{" "}
                  บาท{" "}
                </p>
              </div>
              <div
                className={`${show.bbl ? "h-[400px]" : "h-0"} duration-150 `}
              >
                <div className={`grid grid-cols-11 py-3 gap-3 rounded-lg`}>
                  <div className="flex justify-center items-center">
                    <p className="text-center text-muted-foreground font-medium">
                      ลำดับที่
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground  font-medium">ธนาคาร</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground text-left font-medium">
                      ชื่อบัญชี
                    </p>
                  </div>

                  <div className="flex justify-start items-center col-span-2">
                    <p className="text-muted-foreground font-medium">
                      ชื่อ - นามสกุล
                    </p>
                  </div>

                  <div className="flex justify-end items-center">
                    <p className="text-muted-foreground text-right font-medium">
                      จำนวนเงิน
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เลขอ้างอิง
                    </p>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="text-muted-foreground font-medium">
                      ค่าธรรมเนียม
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      รหัสสาขา
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เบอร์โทรศัพท์
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">อีเมล์</p>
                  </div>
                </div>
                <ScrollArea className={`h-[352px] w-full relative`}>
                  {data
                    .filter((x) => x.bankName === "ธนาคารกรุงเทพฯ")

                    .map((winner, index) => {
                      return (
                        <div
                          className={`grid gap-3 grid-cols-11 rounded-sm border-t mr-2 py-3`}
                          key={index}
                        >
                          <div>
                            <p className="text-center font-semibold">
                              {index + 1}
                            </p>
                          </div>
                          <div className="">
                            <p className="font-medium truncate">
                              {bankTranslater(winner.bankName) || ""}
                            </p>
                          </div>
                          <div>
                            <p className="text-gray-900 font-semibold truncate">
                              {winner.accNo.split("-").join("")}
                            </p>
                          </div>
                          <div className="col-span-2">
                            <p className="truncate">
                              {winner?.firstName + " " + winner?.lastName}
                            </p>
                          </div>
                          <div className="">
                            <p className="text-right font-semibold">
                              {winner.totalPrizes?.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-left">{winner.userId}</p>
                          </div>
                          <div className="">
                            <p className="text-left">Payer (OUR)</p>
                          </div>
                          <div>
                            <p className="text-left">0001</p>
                          </div>
                          <div className="">
                            <p className="text-left">{winner.phone}</p>
                          </div>
                          <div>
                            <p className="truncate text-left">
                              {winner.email || "paranee.t@vproperty.co.th"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </ScrollArea>
              </div>
            </div>
            {/* non_scb */}
            <div
              onClick={() =>
                setShow((prevState) => ({
                  ...prevState,
                  nonScb: !prevState.nonScb,
                }))
              }
              className="border-2 mt-8 rounded-lg border-[#592941] overflow-hidden cursor-pointer"
            >
              <div className="bg-[#592941]  text-white p-3 flex justify-between">
                <p className="font-semibold">
                  NON_SCB{" "}
                  {
                    data.filter((x) =>
                      nonScb.some((item) => item === x.bankName)
                    ).length
                  }{" "}
                  รางวัล
                </p>
                <p className="font-semibold">
                  รางวัลรวมมูลค่า{" "}
                  {data
                    .filter((x) => nonScb.some((item) => item === x.bankName))
                    .reduce((acc, prize) => {
                      acc += prize.totalPrizes;

                      return acc;
                    }, 0)
                    .toLocaleString()}{" "}
                  บาท{" "}
                </p>
              </div>
              <div
                className={`${show.nonScb ? "h-[400px]" : "h-0"} duration-150 `}
              >
                <div className={`grid grid-cols-11 py-3 gap-3 rounded-lg`}>
                  <div className="flex justify-center items-center">
                    <p className="text-center text-muted-foreground font-medium">
                      ลำดับที่
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground  font-medium">ธนาคาร</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground text-left font-medium">
                      ชื่อบัญชี
                    </p>
                  </div>

                  <div className="flex justify-start items-center col-span-2">
                    <p className="text-muted-foreground font-medium">
                      ชื่อ - นามสกุล
                    </p>
                  </div>

                  <div className="flex justify-end items-center">
                    <p className="text-muted-foreground text-right font-medium">
                      จำนวนเงิน
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เลขอ้างอิง
                    </p>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="text-muted-foreground font-medium">
                      ค่าธรรมเนียม
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      รหัสสาขา
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">
                      เบอร์โทรศัพท์
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-muted-foreground font-medium">อีเมล์</p>
                  </div>
                </div>
                <ScrollArea className={`h-[352px] w-full relative`}>
                  {data
                    .filter((x) => nonScb.some((item) => item === x.bankName))

                    .map((winner, index) => {
                      return (
                        <div
                          className={`grid gap-3 grid-cols-11 rounded-sm border-t mr-2 py-3`}
                          key={index}
                        >
                          <div>
                            <p className="text-center font-semibold">
                              {index + 1}
                            </p>
                          </div>
                          <div className="">
                            <p className="font-medium truncate">
                              {bankTranslater(winner.bankName) || ""}
                            </p>
                          </div>
                          <div>
                            <p className="text-gray-900 font-semibold truncate">
                              {winner.accNo.split("-").join("")}
                            </p>
                          </div>
                          <div className="col-span-2">
                            <p className="truncate">
                              {winner?.firstName + " " + winner?.lastName}
                            </p>
                          </div>
                          <div className="">
                            <p className="text-right font-semibold">
                              {winner.totalPrizes?.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-left">{winner.userId}</p>
                          </div>
                          <div className="">
                            <p className="text-left">Payer (OUR)</p>
                          </div>
                          <div>
                            <p className="text-left">0001</p>
                          </div>
                          <div className="">
                            <p className="text-left">{winner.phone}</p>
                          </div>
                          <div>
                            <p className="truncate text-left">
                              {winner.email || "paranee.t@vproperty.co.th"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </ScrollArea>
              </div>
            </div>
            {/* all */}
          </>
        ) : (
          <p className="text-gray-600 text-center text-xl font-semibold py-48">
            ไม่พบคำสั่งซื้อ
          </p>
        ))
      )}
    </div>
  );
};
