import React, { useEffect, useState, useCallback } from "react";
import Context from "../Context";
import ReCAPTCHA from "react-google-recaptcha";
const Login = () => {
  const recaptchaRef = React.createRef();
  const { callApi, profileState } = Context();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [token, setToken] = useState("");

  const onSubmitWithReCAPTCHA = () => {
    const token = recaptchaRef.current.getValue();
    setToken(token);

    // apply to form data
  };
  return (
    <div style={{ verticalAlign: "top", height: "100%" }}>
      <div
        style={{ width: "100%", display: "inline-block", verticalAlign: "top" }}
      >
        {localStorage.accessToken ? (
          <button
            onClick={async () => {
              localStorage.removeItem("accessToken");
              window.location.reload();
            }}
          >
            ออกจากระบบ
          </button>
        ) : (
          <>
            บัญชี:
            <input onChange={(e) => setUsername(e.target.value)} />
            <br />
            <br />
            รหัส:
            <input
              type={"password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <br />
            <form className="my-8" onSubmit={onSubmitWithReCAPTCHA}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LepvVYqAAAAAOZudU8U6HwAe1h2AFpxtQCx7R0i"
                onChange={() => onSubmitWithReCAPTCHA()}
              />
            </form>
            {token && (
              <button
                onClick={async () => {
                  if (!token) return;
                  const result = await callApi("users-login", {
                    username,
                    password,
                    reCaptchaToken: token,
                    admin: true,
                  });

                  if (result.success) {
                    localStorage.setItem(
                      "accessToken",
                      result.data.accessToken
                    );
                  } else {
                    localStorage.removeItem("accessToken");
                  }
                  window.location.reload();
                }}
              >
                เข้าระบบ
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default Login;
