import { useState, useEffect, useRef } from "react";
import Context from "../Context";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components";
import { CSVLink } from "react-csv";
import { thaiDate } from "../util";
import { Loading } from "../components/modules";
import { Container, Text, Switch } from "../components/base";
import {
  ScrollArea,
  Separator,
  useToast,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui";
import { FaTimes, FaCheckCircle } from "react-icons/fa";

export const LaoOrder = () => {
  const { callApi, configs } = Context();
  const { toast } = useToast();
  const downloadRef = useRef(null);
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("ss00001");

  const [orders, setOrders] = useState([]);
  const [ssOrders, setssOrders] = useState([]);
  const [lottos, setLottos] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    getOrders();
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const getOrders = async () => {
    setLoading(true);
    const orders = await callApi("orders-getAll", {
      queries: [
        ["paidCurrency", "==", "LAK"],
        ["roundDate", "==", configs.roundDate],
        ["status", "==", "approved"],
      ],
      limit: 2000,
    });

    const ssOrders = await callApi("lotteries-getAll", {
      queries: [
        ["roundNo", "==", "ss"],
        ["roundDate", "==", configs.roundDate],
      ],
      limit: 2000,
    });

    setLoading(false);
    setssOrders(ssOrders);
    setOrders(orders || []);

    const laoLotteries = orders.reduce((acc, lotto) => {
      acc.push(...lotto.lotteries);
      return acc;
    }, []);

    setLottos(laoLotteries);
  };
  const markOrder = async () => {
    setLoading(true);

    const lotteries = selectedOrders.map((order) => order.id);

    const { status, count } = await callApi("orders-markOrders", {
      orders: lotteries,
      message,
    });
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูล {count} ออเดอร์สำเร็จ
          </div>
        ),
      });

      handleDownload();
      setSelectedOrders([]);
      setLoading(false);
      getOrders();
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
      });
      setLoading(false);
    }

    setLoading(false);
  };

  const handleDownload = async () => {
    let count = 0;
    const lotteriesId = selectedOrders.reduce((acc, order) => {
      acc.push(...order.lotto.map((x) => x.id));
      return acc;
    }, []);

    const { status, lottoImages } = await callApi(
      "lotteries-downloadLottoImages",
      {
        lotteriesId: lotteriesId,
      }
    );
    if (status) {
      for await (let lotterie of lottoImages) {
        await download(lotterie.id, lotterie.imagePublic, lotterie.bookNo);
        count++;
        console.log(count);
      }
    }
  };

  const download = (id, url, bookNo) =>
    new Promise((resolve, reject) => {
      try {
        let [yy, rr, ss, number] = id.split("-");

        const ids = [yy, "ss", ss, number, bookNo].join("-");
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = document.createElement("img");
        img.crossOrigin = "anonymous";
        img.onload = async () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => {
            const link = document.createElement("a");
            link.download = ids + ".jpeg";
            link.href = URL.createObjectURL(blob);
            link.click();
            URL.revokeObjectURL(link.href);
          }, "image/jpeg");
          setTimeout(() => {
            resolve("");
          }, 100);
        };
        img.src = url;
      } catch (err) {
        console.error(err);
      }
    });

  return (
    <Container className="w-full mb-24  p-8 rounded-lg drop-shadow-xl">
      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        ></CSVLink>
      </div>
      <Text className={`text-xl font-semibold`}>งวด {configs.roundDate}</Text>
      <div className="grid grid-cols-4 gap-2 my-3">
        <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#2E5077] text-white rounded-2xl duration-300">
          <p className="text-sm font-medium">ออเดอร์ทั้งหมด (🇱🇦 ลาว)</p>
          <div className="text-2xl text-center font-bold">
            {isLoading ? <Loading /> : orders?.length.toLocaleString()}
          </div>
          <p className="text-sm text-right font-medium"> ออเดอร์</p>
        </div>
        <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#4DA1A9] text-white rounded-2xl duration-300">
          <p className="text-sm font-medium">สลากทั้งหมด (🇱🇦 ลาว)</p>
          <div className="text-2xl text-center font-bold">
            {isLoading ? <Loading /> : lottos?.length.toLocaleString()}
          </div>
          <p className="text-sm text-right font-medium"> ใบ</p>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-2 my-3">
        <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#4DA1A9] text-white rounded-2xl duration-300">
          <p className="text-sm font-medium">สลาก ss ทั้งหมด</p>
          <div className="text-2xl text-center font-bold">
            {isLoading ? <Loading /> : ssOrders?.length.toLocaleString()}
          </div>
          <p className="text-sm text-right font-medium"> ใบ</p>
        </div>
        <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#4DA1A9] text-white rounded-2xl duration-300">
          <p className="text-sm font-medium">สลาก ss ที่ขายแล้ว</p>
          <div className="text-2xl text-center font-bold">
            {isLoading ? (
              <Loading />
            ) : (
              ssOrders.filter((x) => x.isSold)?.length.toLocaleString()
            )}
          </div>
          <p className="text-sm text-right font-medium"> ใบ</p>
        </div>
        {/* <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#4DA1A9] text-white rounded-2xl duration-300">
          <p className="text-sm font-medium">สลาก ss ที่ยังไม่ขายแล้ว</p>
          <div className="text-2xl text-center font-bold">
            {isLoading ? (
              <Loading />
            ) : (
              ssOrders.filter((x) => !x.isSold)?.length.toLocaleString()
            )}
          </div>
          <p className="text-sm text-right font-medium"> ใบ</p>
        </div> */}
      </div>

      <Text className={`mt-8`}>Mark message</Text>
      <textarea
        className="p-3 bg-gray-100 w-96 h-56 rounded-md"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="flex">
        <button
          className="bg-gray-900 rounded-lg px-3 pt-2.5 pb-2 text-white font-semibold min-w-24 disabled:bg-gray-200 disabled:cursor-not-allowed"
          onClick={() => markOrder()}
          disabled={selectedOrders.length === 0}
        >
          {isLoading ? <Loading className={`h-4 w-4`} /> : "Mark order"}
        </button>
        <button
          className="bg-[#c15b78] rounded-lg ml-2 px-3 pt-2.5 pb-2 text-white font-semibold min-w-24 disabled:bg-gray-200 disabled:cursor-not-allowed"
          onClick={() => handleDownload()}
          disabled={selectedOrders.length === 0}
        >
          ดาวน์โหลดรูป
        </button>
      </div>
      <div className="flex mt-2">
        <button
          className="bg-gray-900 rounded-lg px-3 pt-2.5 pb-2 text-white font-semibold min-w-24 disabled:bg-gray-200 disabled:cursor-not-allowed"
          onClick={() =>
            setSelectedOrders(
              orders
                .filter((x) => x.mark !== "ss00001")
                .slice(0, 10)
                .map((order) => {
                  return { id: order.id, lotto: order.lotteries };
                })
            )
          }
          disabled={orders.length === 0}
        >
          เลือก 10 ออเดอร์แรก
        </button>
      </div>
      <div>
        <Table className={`mt-11 `}>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[160px] text-center">
                <p
                  className="hover:cursor-pointer"
                  onClick={() =>
                    setSelectedOrders(
                      selectedOrders.length > 0
                        ? []
                        : orders
                            .filter(
                              (order) => !order.mark || order.mark !== "ss00001"
                            )
                            .map((order) => {
                              return { id: order.id, lotto: order.lotteries };
                            })
                    )
                  }
                >
                  {selectedOrders.length > 0
                    ? "ยกเลิกทั้งหมด "
                    : "เลือกทั้งหมด "}
                  <span className="font-semibold">
                    ({selectedOrders.length} ออเดอร์{" "}
                    {console.log("selectedOrders", selectedOrders)}
                    {selectedOrders.reduce((acc, order) => {
                      acc += order.lotto.length;
                      return acc;
                    }, 0)}{" "}
                    ใบ )
                  </span>
                </p>
              </TableHead>
              <TableHead className="w-[60px] text-center">ลำดับ</TableHead>
              <TableHead className={`text-center w-[120px]`}>shortId</TableHead>
              <TableHead className={`text-center w-[80px]`}>
                จำนวน (ใบ)
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {orders.map((order, index) => {
              return (
                <TableRow key={index} className={`align-top`}>
                  <TableCell className="font-medium text-center">
                    <input
                      type="checkbox"
                      checked={
                        selectedOrders.some((x) => x.id === order.id) ||
                        order.mark === "ss00001"
                      }
                      disabled={order.mark === "ss00001"}
                      onChange={(e) => {
                        e.target.checked
                          ? setSelectedOrders((prevState) => [
                              ...prevState,
                              { id: order.id, lotto: order.lotteries },
                            ])
                          : setSelectedOrders((prevState) =>
                              prevState.filter((x) => x.id !== order.id)
                            );
                      }}
                    />
                  </TableCell>
                  <TableCell className="font-medium text-center">
                    {index + 1}
                  </TableCell>
                  <TableCell className={`font-bold text-center`}>
                    {order.shortId}
                  </TableCell>

                  <TableCell className="text-center font-bold text-green-500">
                    {order.size}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Container>
  );
};
