import Home from "./Home";
import Overall from "./dashboard/Overall";
import General from "./settings/General";
import Prices from "./settings/Prices";
import MailSetting from "./settings/MailSetting";
import Currency from "./settings/Currency";

import WorkingOrder from "./orders/WorkingOrder";

import OrderListMail from "./orders/OrderListMail";
import UserList from "./users/List";
import User from "./users/User";
import CreateUser from "./users/CreateUser";
import Points from "./users/Points";
import ReferralPoints from "./users/ReferralPoints";
import AdminList from "./admins/AdminList";
import Admin from "./admins/Admin";
import ComplainList from "./complains/ComplainList";
import LogList from "./logs/LogList";
import Login from "./Login";
// import Prizes from "./Prizes"
// import Summary from "./Summary"
// import Audit from "./Audit"

import ManageLotteries from "./lotteries/ManageLotteries";
import Unimported from "./lotteries/Unimported";
import OffBlockOrder from "./lotteries/OffBlockOrder";
import Imported from "./lotteries/Imported";
import LotteriesNotImported from "./lotteries/LotteriesNotImported";
import LotteriesImported from "./lotteries/LotteriesImported";
import LotteriesDetail from "./lotteries/LotteriesDetail";
import CheckBookLotteries from "./lotteries/CheckBookLotteries";

import DownloadImage from "./lotteries/DownloadImage";

import PrizesMakeReward from "./prizes/PrizesMakeReward";
import PrizesPay from "./prizes/PrizesPay";
import PrizesList from "./prizes/List";
import UserPrize from "./prizes/UserPrize";
import LotteriesPrizes from "./prizes/LotteriesPrizes";
import NewOrdersList from "./orders/NewOrderList";
import NewOrdersApprovedList from "./orders/NewOrderApproveList";
import NewOrdersCanceledList from "./orders/NewOrdersCanceledList";
import NewOrdersPendingList from "./orders/NewOrderPendingList";
import NewOrdersOfflineList from "./orders/NewOrderOfflineList";
import NewOrdersPaidList from "./orders/NewOrderPaidList";
import WithdrawList from "./withdraw/withdrawList";
import WithdrawPendingList from "./withdraw/withdrawPending";
import WithdrawApprovedList from "./withdraw/withdrawApproved";

import {
  PointRedeemApprovedList,
  PointRedeemPaidList,
  PointRedeemList,
  PointRedeemDetail,
} from "./pointRedeems";

import {
  ManageDonate,
  DonateList,
  DonatePaidList,
  DonateDetail,
} from "./donates";

import {
  ManageCoupon,
  CouponOrderList,
  CouponOrderDetail,
  CouponReservedOrder,
} from "./coupons";

import { ManageMission, MissionDetail, MissionList } from "./missions";
import Cart from "./orders/Cart";
import {
  ReportDaily,
  ReportMails,
  ReportRewardwinner,
  ReportBookWinner,
  ReportBookSummary,
  ReportUploader,
  ReportBookWinnerSummary,
  ReportPointRedeem,
  RoundDateBuyerList,
  ReportTopBuyer,
  ReportShop,
  ReportAdminApproved,
  ReportCoupon,
  ReportUser,
  ReportFilteredRewardwinner,
  ReportSale,
  ReportUserPrizes,
  ReportReserveOrder,
} from "./reports";
import {
  FiHome,
  FiPieChart,
  FiShoppingBag,
  FiCreditCard,
  FiSettings,
  FiGift,
  FiUsers,
  FiUserCheck,
  FiFrown,
  FiVideo,
  FiFileText,
  FiDollarSign,
  FiSmile,
  FiShoppingCart,
} from "react-icons/fi";
import { RiCoupon2Fill } from "react-icons/ri";
import { GrBike } from "react-icons/gr";

import {
  ManageGiftshop,
  GiftshopOrderList,
  GiftshopOrderApprovedList,
  GiftshopOrderPaidList,
  GiftshopOrderDetail,
} from "./giftshop";
import {
  ManageProducts,
  ProductOrderList,
  ProductOrderApprovedList,
  ProductOrderPaidList,
  ProductOrderDetail,
} from "./product";
const pages = [
  {
    path: "/login",
    Component: <Login />,
    icon: <FiHome />,
    name: "บัญชี",
    children: [],
  },
  {
    hideSideBar: true,
    path: "/",
    Component: <Home />,
    icon: <FiHome />,
    name: "หน้าหลัก",
    children: [],
  },

  // {
  //   path: "/dashboard",
  //   icon: <FiPieChart />,
  //   name: "แดชบอร์ด",
  //   children: [
  //     {
  //       path: "/overall",
  //       Component: <Overall />,
  //       name: "ภาพรวม",
  //       permissions: [
  //         "dev",
  //         "accountant",
  //         "admin",
  //         "superAdmin",
  //         "superScanner",
  //       ],
  //     },
  //   ],
  // },

  {
    path: "/lotteries",
    icon: <FiCreditCard />,
    name: "ลอตเตอรี่",
    children: [
      {
        path: "/lotteries/unimported",
        Component: <Unimported />,
        icon: "lotteries.png",
        name: "ลอตเตอรี่ที่มีปัญหา",
        permissions: ["dev", "scanner", "admin", "superScanner", "superAdmin"],
      },
      {
        path: "/lotteries/all",
        Component: <Imported />,
        icon: "lotteries.png",
        name: "ลอตเตอรี่ในระบบทั้งหมด",
        permissions: ["dev", "scanner", "admin", "superScanner", "superAdmin"],
      },

      {
        path: "/lotteries/notImported",
        Component: <LotteriesNotImported />,
        icon: "lotteries.png",
        name: "เล่มลอตเตอรี่ในระบบหลังบ้าน",
        permissions: ["dev", "scanner", "admin", "superScanner", "superAdmin"],
      },

      {
        path: "/lotteries/imported",
        Component: <LotteriesImported />,
        icon: "lotteries.png",
        name: "เล่มลอตเตอรี่ในระบบพร้อมขาย",
        permissions: ["dev", "scanner", "admin", "superScanner", "superAdmin"],
      },
      {
        hideSideBar: true,
        path: "/lotteries/detail",
        Component: <LotteriesDetail />,
        icon: "lotteries.png",
        name: "ข้อมูลเล่มลอตเตอรี่",
        permissions: ["dev", "scanner", "admin", "superScanner", "superAdmin"],
      },

      {
        hideSideBar: true,
        path: "/lotteries/manage",
        Component: <ManageLotteries />,
        icon: "lotteries.png",
        name: "จัดการลอตเตอรี่",
        permissions: ["dev", "scanner", "admin", "superScanner", "superAdmin"],
      },
      {
        path: "/lotteries/download",
        Component: <DownloadImage />,
        icon: "lotteries.png",
        name: "โหลดรูป",
        permissions: ["dev"],
      },
      {
        path: "/lotteries/check-book",
        Component: <CheckBookLotteries />,

        name: "ตรวจเล่มลอตเตอรี่",
      },
      // {
      //   hideSideBar: true,
      //   path: "/lotteries/bookList",
      //   Component: <BookList />,
      //   icon: "lotteries.png",
      //   name: "รายการเล่มลอตเตอรี่",
      //   permissions: ["dev"],
      // },
      {
        path: "/coupon/list/reserved",
        Component: <CouponReservedOrder />,
        name: "คูปองจองสิทธิ์",
      },
      // {
      //   path: "/lotteries/adminOrder",
      //   Component: <OffBlockOrder />,
      //   icon: "lotteries.png",
      //   name: "จองลอตเตอรี่",
      //   permissions: ["dev"],
      // },
      {
        path: "/lotteries/adminOrder",
        Component: <OffBlockOrder />,
        icon: "lotteries.png",
        name: "---------------",
        permissions: ["dev"],
      },
    ],
  },
  {
    path: "/orders",
    icon: <FiShoppingBag />,
    name: "คำสั่งซื้อ",
    children: [
      {
        path: "/orders/orders",
        Component: <NewOrdersList />,
        icon: "orders.png",
        name: "คำสั่งซื้อทั้งหมด ",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/orders/orders-approved",
        Component: <NewOrdersApprovedList />,
        icon: "orders.png",
        name: "คำสั่งซื้อสำเร็จ ",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },

      {
        path: "/orders/orders-paid",
        Component: <NewOrdersPaidList />,
        icon: "orders.png",
        name: "คำสั่งซื้อที่จ่ายเงินแล้ว ",
        permissions: [
          "dev",
          "scanner",
          "admin",
          "superAdmin",
          "superScanner",
          "scanner",
        ],
      },
      {
        path: "/orders/orders-pending",
        Component: <NewOrdersPendingList />,
        icon: "orders.png",
        name: "คำสั่งซื้อที่รอดำเนินการ ",
        permissions: ["dev", "admin", "superAdmin"],
      },
      {
        path: "/orders/orders-canceled",
        Component: <NewOrdersCanceledList />,
        icon: "orders.png",
        name: "คำสั่งซื้อที่ถูกยกเลิก ",
        permissions: ["dev", "admin", "superAdmin"],
      },
      {
        path: "/orders/searchMail",
        Component: <OrderListMail />,
        icon: "orders.png",
        name: "ไปรษณีย์",
        permissions: ["dev", "admin", "superScanner", "superAdmin"],
      },
      {
        path: "/orders/orders-offline",
        Component: <NewOrdersOfflineList />,
        icon: "orders.png",
        name: "คำสั่งซื้อ 80 บาท ",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
      {
        path: "/orders/cart",
        Component: <Cart />,
        icon: "orders.png",
        name: "คำสั่งซื้อค้างตะกร้า",
        permissions: ["dev", "admin", "superAdmin"],
      },
      // {
      //   path: "/orders/working/",
      //   Component: <WorkingOrder />,
      //   icon: "orders.png",
      //   name: "คำสั่งซื้อปัจจุบันแบบสุ่ม",
      // },
      {
        path: "/orders/working/:orderId",
        hideSideBar: true,
        Component: <WorkingOrder />,
        icon: "orders.png",
        name: "คำสั่งซื้อปัจจุบันแบบเจาะจง",
        permissions: [
          "dev",
          "scanner",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
    ],
  },
  {
    path: "/withdraw",
    icon: <FiDollarSign />,
    name: "ถอนเงิน",
    children: [
      {
        path: "/withdraw/all",
        Component: <WithdrawList />,
        name: "คำขอถอนเงินทั้งหมด",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/withdraw/pending",
        Component: <WithdrawPendingList />,
        name: "คำขอถอนเงินรอดำเนินการ",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/withdraw/approved",
        Component: <WithdrawApprovedList />,
        name: "คำขอถอนเงินสำเร็จ",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
    ],
  },
  {
    path: "/product",
    icon: <FiShoppingCart />,
    name: "สต๊อคสินค้า",
    children: [
      {
        path: "/product/manage",
        Component: <ManageProducts />,
        name: "จัดการสินค้า",
        permissions: ["dev", "admin", "superAdmin"],
      },
    ],
  },
  {
    path: "/point-redeem",
    icon: <RiCoupon2Fill />,
    name: "แลกคะแนน",
    children: [
      {
        path: "/point-redeem/all",
        Component: <PointRedeemList />,
        name: "แลกคะแนนทั้งหมด",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/point-redeem/approved",
        Component: <PointRedeemApprovedList />,
        name: "แลกคะแนนอนุมัติแล้ว",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/point-redeem/paid",
        Component: <PointRedeemPaidList />,
        name: "แลกคะแนนรออนุมัติ",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/point-redeem/:orderId",
        Component: <PointRedeemDetail />,
        name: "รายระเอียดออเดอร์",
        hideSideBar: true,
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
    ],
  },
  {
    path: "/giftshop",
    icon: <FiShoppingCart />,
    name: "ร้านค้า",
    children: [
      {
        path: "/giftshop/all",
        Component: <GiftshopOrderList />,
        name: "คำสั่งซื้อทั้งหมด",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/giftshop/approved",
        Component: <GiftshopOrderApprovedList />,
        name: "คำสั่งซื้ออนุมัติแล้ว",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/giftshop/paid",
        Component: <GiftshopOrderPaidList />,
        name: "คำสั่งซื้อรออนุมัติ",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },

      {
        path: "/giftshop/:orderId",
        Component: <GiftshopOrderDetail />,
        name: "รายระเอียดออเดอร์",
        hideSideBar: true,
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
    ],
  },
  {
    path: "/donates",
    icon: <FiSmile />,
    name: "บริจาค",
    children: [
      {
        path: "/donates/manage",
        Component: <ManageDonate />,
        name: "จัดการบริจาค",
        permissions: ["dev", "admin", "superAdmin"],
      },
      {
        path: "/donates/all",
        Component: <DonateList />,
        name: "บริจาคทั้งหมด",
        permissions: ["dev", "admin", "superAdmin"],
      },
      {
        path: "/donates/paid",
        Component: <DonatePaidList />,
        name: "บริจาครออนุมัติ",
        permissions: ["dev", "admin", "superAdmin"],
      },

      {
        path: "/donates/:orderId",
        Component: <DonateDetail />,
        name: "รายระเอียดออเดอร์",
        hideSideBar: true,
        permissions: ["dev", "admin", "superAdmin"],
      },
    ],
  },

  {
    path: "/missions",
    icon: <GrBike />,
    name: "ภารกิจ",
    children: [
      {
        path: "/mission/manage",
        Component: <ManageMission />,
        name: "จัดการภารกิจ",
      },
      {
        path: "/mission/list",
        Component: <MissionList />,
        name: "คำสั่งขอแลกรางวัลทั้งหมด",
      },
      {
        path: "/mission/list/:missionId",
        Component: <MissionList />,
        hideSideBar: true,
        name: "คำสั่งขอแลกรางวัลทั้งหมด",
      },

      {
        path: "/mission/:missionId",
        Component: <MissionDetail />,
        hideSideBar: true,
        name: "รายละเอียดภารกิจ",
      },
    ],
  },
  {
    path: "/coupons",
    icon: <FiSmile />,
    name: "คูปอง",
    children: [
      {
        path: "/coupon/manage",
        Component: <ManageCoupon />,
        name: "จัดการคูปอง",
      },
      {
        path: "/coupon/list",
        Component: <CouponOrderList />,
        name: "คำสั่งซื้อคูปองทั้งหมด",
      },

      {
        path: "/coupon/:orderId",
        Component: <CouponOrderDetail />,
        hideSideBar: true,
        name: "รายละเอียดคำสั่งซื้อคูปอง",
      },
    ],
  },
  {
    path: "/users",
    icon: <FiUsers />,
    name: "ลูกค้า",
    children: [
      {
        hideSideBar: true,
        path: "/users/:userId",
        Component: <User />,
        name: "รายละเอียดลูกค้า",
        permissions: ["dev", "admin", "superAdmin"],
      },
      {
        path: "/users",
        Component: <UserList />,
        name: "ค้นหาลูกค้า",
        permissions: ["dev", "admin", "superAdmin"],
      },
      {
        path: "/users/points",
        Component: <Points />,
        name: "คะแนน",
        permissions: ["dev", "admin", "superAdmin"],
      },
      {
        path: "/users/referralPoints",
        Component: <ReferralPoints />,
        name: "คะแนนชวนเพื่อน",
        permissions: ["dev", "admin", "superAdmin"],
      },
      {
        path: "/users/new",
        Component: <CreateUser />,
        name: "สร้างบัญชีใหม่",
        permissions: ["dev", "admin", "superAdmin"],
      },
    ],
  },
  {
    path: "/admins",
    icon: <FiUserCheck />,
    name: "พนักงาน",
    children: [
      {
        path: "/admins/list",
        Component: <AdminList />,
        icon: "user.png",
        name: "ค้นหาพนักงาน",
        permissions: ["dev"],
      },
      {
        hideSideBar: true,
        path: "/admins/:userId",
        Component: <Admin />,
        icon: "user.png",
        name: "สิทธิ์การเข้าถึง",
        permissions: ["dev"],
      },
    ],
  },
  {
    path: "/complains",
    Component: <ComplainList />,
    icon: <FiFrown />,
    name: "ร้องเรียน",
    permissions: ["dev", "admin", "superAdmin"],
    children: [],
  },
  {
    path: "/prizes",
    // Component: <Prizes />,
    icon: <FiGift />,
    name: "รางวัล",
    children: [
      {
        path: "/prizes-makeReward",
        Component: <PrizesMakeReward />,
        name: "สร้างรางวัล",
        permissions: ["dev"],
      },
      {
        path: "/prizes-list",
        Component: <PrizesList />,
        name: "รางวัลรายบุคคล",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
      {
        path: "/prizes-pay",
        Component: <PrizesPay />,
        name: "การจ่ายรางวัล",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
      {
        hideSideBar: true,
        path: "/prizes-list/:userId",
        Component: <UserPrize />,
        name: "รางวัลเฉพาะบุคคล",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
      {
        path: "/prizes-list/lotteriesPrizes",
        Component: <LotteriesPrizes />,
        name: "เล่มที่ถูกรางวัล",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
    ],
  },
  {
    path: "/logs",
    Component: <LogList />,
    icon: <FiVideo />,
    name: "บันทึกระบบ",
    children: [],
    permissions: ["dev"],
  },
  {
    path: "/reports",
    icon: <FiFileText />,
    name: "รายงาน",
    permissions: ["dev"],
    children: [
      {
        path: "/reports/buyer-list",
        Component: <RoundDateBuyerList />,
        name: "รายชื่อผู้ซื้องวดนี้",
        permissions: ["dev", "superAdmin"],
      },
      {
        path: "/reports/top-buyer",
        Component: <ReportTopBuyer />,
        name: "รายชื่อผู้ซื้อ Top 100",
        permissions: ["dev", "superAdmin"],
      },

      {
        path: "/reports/daily",
        Component: <ReportDaily />,
        name: "รายงานยอดขายรายวัน",
        permissions: ["dev", "accountant", "superScanner", "superAdmin"],
      },

      {
        path: "/reports/all-user",
        Component: <ReportUser />,
        name: "รายงานผู้ใช้ทั้งหมด",
      },
      {
        path: "/reports/mail",
        Component: <ReportMails />,
        name: "รายงานไปรษณีย์",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },

      {
        path: "/reports/coupon",
        Component: <ReportCoupon />,
        name: "รายงานคูปอง",
      },
      {
        path: "/reports/sale",
        Component: <ReportSale />,
        name: "รายงานการขาย (โอนเงิน)",
      },

      {
        path: "/reports/shop",
        Component: <ReportShop />,
        name: "รายงานร้านค้า",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },
      {
        path: "/reports/admin-approved",
        Component: <ReportAdminApproved />,
        name: "รายงานแอดมินอนุมัติออเดอร์",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },

      {
        path: "/reports/point",
        Component: <ReportPointRedeem />,
        name: "รายงานแลกคะแนน",
        permissions: ["dev", "accountant", "admin", "superAdmin"],
      },

      // {
      //   path: "/reports/mail-adimin",
      //   Component: <ReportMailForAdmin />,
      //   name: "รายงานไปรษณีย์สำหรับแอดมิน",
      // },

      // {
      //   path: "/reports/book-deliver",
      //   Component: <ReportBookDeliver />,
      //   name: "รายงานเล่มที่มีการจัดส่ง",
      // },

      {
        path: "/reports/book-summary",
        Component: <ReportBookSummary />,
        name: "รายงานสรุปการนำเล่มเข้าระบบ",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
      {
        path: "/reports/book-winner-summary",
        Component: <ReportBookWinnerSummary />,
        name: "รายงานสรุปจำนวนรางวัล",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
      {
        path: "/reports/reserve-order",
        Component: <ReportReserveOrder />,
        name: "รายงานการสร้างออเดอร์จองสิทธิ์",
      },
      {
        path: "/reports/book-winner",
        Component: <ReportBookWinner />,
        name: "รายงานเล่มที่ถูกรางวัล",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },

      {
        path: "/reports/reward-winner-filtered",
        Component: <ReportFilteredRewardwinner />,
        name: "รายงานผู้ถูกรางวัลแบบแยกประเภท",
      },
      {
        path: "/reports/report-alert-userprizes",
        Component: <ReportUserPrizes />,
        name: "รายงานการแจ้งเตือนผู้ถูกรางวัล",
      },

      {
        path: "/reports/reward-winner",
        Component: <ReportRewardwinner />,
        name: "รายงานผู้ถูกรางวัล",
        permissions: [
          "dev",
          "accountant",
          "admin",
          "superScanner",
          "superAdmin",
        ],
      },
      // {
      //   path: "/reports/reward-winner-admin",
      //   Component: <ReportRewardwinnerForAdmin />,
      //   name: "รายงานผู้ถูกรางวัลสำหรับแอดมิน",
      // },
      // {
      //   path: "/reports/special",
      //   Component: <ReportSpecial />,
      //   name: "รายงานพิเศษ",
      // },
      {
        path: "/reports/uploader",
        Component: <ReportUploader />,
        name: "รายงานเฉพาะ scanner",
        permissions: ["dev", "superScanner"],
      },

      // {
      //   path: "/reports/reward-winner-new",
      //   Component: <ReportRewardwinnerNew />,
      //   name: "รายงานผู้ถูกรางวัล (ทดสอบ)",
      // },
      // {
      //   path: "/reports/reward-temp",
      //   Component: <ReportTemp />,
      //   name: "รายงานออเดอร์ 120+ (ทดสอบ)",
      // },
    ],
  },

  {
    path: "/setting",
    icon: <FiSettings />,
    name: "ตั้งค่า",
    children: [
      {
        path: "/general",
        Component: <General />,
        name: "ทั่วไป",
        permissions: ["dev"],
      },
      {
        path: "/mail",
        Component: <MailSetting />,
        name: "ไปรษณีย์",
        permissions: ["dev", "superAdmin", "admin"],
      },
      {
        path: "/prices",
        Component: <Prices />,
        name: "ราคา",
        permissions: ["dev", "superAdmin", "admin"],
      },
      {
        path: "/currency",
        Component: <Currency />,
        name: "ค่าเงินต่างชาติ",
        permissions: ["dev", "superAdmin", "admin"],
      },
    ],
  },
];
export const childPages = pages.reduce((acc, x) => {
  return [...acc, ...x.children];
}, []);
export default pages;
